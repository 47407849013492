import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

const ModalCreate = (props) => {
    const [genders, setGenders] = useState([])
    const [directorates, setDirectorates] = useState([])
    const [centers, setCenters] = useState([])
    const [tracks, setTracks] = useState([])

    const [formData, setFormData] = useState({
        directorateId:0,
        centerId: 0,
        firstName: "string",
        fatherName: "string",
        lastName: "string",
        genderId: 0
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        getdirectorates();
        getCenters();
        getGenders();
        gettrackTypeId();
    }, []);

    const getdirectorates = async () => {
        try {
            const response = await Api.fetch({ url: 'Directorates' })
            if (response) {
                // console.log(response);
                setDirectorates(response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getCenters = async () => {
        try {
            const response = await Api.fetch({ url: 'Centers/getCenters' })
            if (response) {
                // console.log(response);
                setCenters(response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getGenders = async () => {
        try {
            const response = await Api.fetch({ url: 'Genders' });
            if (response) {
                setGenders(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const gettrackTypeId = async () => {
        try {
            const response = await Api.fetch({ url: 'TracksTypes' });
            // console.log(response);
            if (response) {
                setTracks(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addCompetitorsInfo = async () => {
        console.log(formData);
        try {
            const response = await Api.fetch({
                url: 'CompetitorsInfoes/addCompetitorsInfoes',
                body: formData,
                method: 'POST',
            });

            if (response) {
                toast.success("تمت الاضافة بنجاح");
                props.onHide();
                props.onUpdat();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">اضافة متسابق</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="directorateId">المديرية</Label>
                                            <select id="directorateId" name="directorateId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                            }}>
                                                <option>اختر المديرية</option>
                                                {directorates.map((item, index) => (
                                                    <option key={index} value={item.directorateId}>{item.directorateIname}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="centerId">المركز</Label>
                                            <select id="centerId" name="centerId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                            }}>
                                                <option>اختر المركز</option>
                                                {centers.map((item, index) => (
                                                    <option key={index} value={item.CenterID}>{item.CenterName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="trackTypeId">المسار</Label>
                                            <select id="trackTypeId" name="trackTypeId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                            }}>
                                                <option>اختر المسار</option>
                                                {tracks.map((item, index) => (
                                                    <option key={index} value={item.trackTypeId}>{item.trackTypeName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="firstName">الاسم المتسابق</Label>
                                            <Input type="text" className="form-control" id="firstName" name='firstName' placeholder="اسم المتسابق" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="fatherName">اسم الاب</Label>
                                            <Input type="text" className="form-control" id="fatherName" name='fatherName' placeholder="اسم الاب" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="lastName">الكنية</Label>
                                            <Input type="text" className="form-control" id="lastName" name='lastName' placeholder="الكنية" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="genderId">الجنس</Label>
                                            <select id="genderId" name="genderId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                            }}>
                                                <option>اختر الجنس</option>
                                                {genders.map((item, index) => (
                                                    <option key={index} value={item.genderId}>{item.genderName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={addCompetitorsInfo}>اضافة</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
