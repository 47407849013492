import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ModalCreate from './ModalCreate'
import { MdDeleteForever } from "react-icons/md";
import { FaPencil } from "react-icons/fa6";
import ModalEdite from './ModalEdite';
import ModalDelete from './ModalDelete';
import Api from '../../../tools/api';
import ModalCreateD from './ModalCreateD';
import ModalDeleteD from './ModalDeleteD';
import LoadingSpinner from '../../../tools/elements/LoadingSpinner';

const Settings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowD, setModalShowD] = React.useState(false);
    const [modalEditeShow, setModalEditeShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [modalDeleteShowD, setModalDeleteShowD] = React.useState(false);
    const [itemid, setItem] = useState([])
    const [settings, setSettings] = useState([])
    const [devicesIdentifications, setDevicesIdentifications] = useState([])

    useEffect(() => {
        getSettings();
        getDevicesIdentification();
    }, []);
    const getSettings = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: 'CompetitionsSettings' })
            if (response) {
                console.log(response);
                setSettings(response);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getDevicesIdentification = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: 'DevicesIdentifications' })
            if (response) {
                console.log(response);
                setDevicesIdentifications(response);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <>
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShow(true)}>
                    اضافة عدد التابات
                </Button>
                <ModalCreate
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onUpdat={() => getSettings()}
                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>رقم المسابقة</th>
                            <th>اسم المسابقة</th>
                            <th>عدد الاجهزة</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <LoadingSpinner/>
                        ) : (
                            settings == null ? (
                                <div>لا يوجد مشرفين</div>
                            ) : (
                                <>
                                    {settings.map((item, index) => (
                                        <tr className='table-tr'>
                                            <td className='head-color1'>{item.CompetitionID}</td>
                                            <td className='head-color2'>{item.CompetitionName}</td>
                                            <td className='head-color2'>{item.TabletsNo}</td>
                                            <td className='head-color1'><MdDeleteForever onClick={() => {
                                                setItem(item)
                                                setModalDeleteShow(true);
                                            }} /> / <FaPencil onClick={() => {
                                                setItem(item);
                                                setModalEditeShow(true)
                                            }} /></td>
                                            <ModalEdite
                                                show={modalEditeShow}
                                                onHide={() => setModalEditeShow(false)}
                                                onUpdat={() => getSettings()}
                                                itemid={itemid}
                                            />
                                            <ModalDelete
                                                show={modalDeleteShow}
                                                onHide={() => setModalDeleteShow(false)}
                                                onUpdat={() => getSettings()}
                                                itemid={itemid}
                                            />
                                        </tr>))}
                                </>
                            )
                        )}
                    </tbody>
                </table>
            </div>
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShowD(true)}>
                    اضافة الاجهزة
                </Button>
                <ModalCreateD
                    show={modalShowD}
                    onHide={() => setModalShowD(false)}
                    onUpdat={() => getDevicesIdentification()}
                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>رقم الجهاز</th>
                            <th>معرف الجهاز</th>
                            <th>اسم الجهاز</th>
                            <th>عدد الجهاز</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <h1>انتظر...</h1>
                        ) : (
                            settings == null ? (
                                <div>لا يوجد مشرفين</div>
                            ) : (
                                <>
                                    {devicesIdentifications.map((item, index) => (
                                        <tr className='table-tr'>
                                            <td className='head-color1'>{item.tabletId}</td>
                                            <td className='head-color2'>{item.deviceId}</td>
                                            <td className='head-color2'>{item.tabletName}</td>
                                            <td className='head-color2'>{item.tabletNo}</td>
                                            <td className='head-color1'><MdDeleteForever onClick={() => {
                                                setItem(item)
                                                setModalDeleteShowD(true);
                                            }} /> / <FaPencil onClick={() => {
                                                setItem(item);
                                                setModalEditeShow(true)
                                            }} /></td>
                                            <ModalEdite
                                                show={modalEditeShow}
                                                onHide={() => setModalEditeShow(false)}
                                                onUpdat={() => getDevicesIdentification()}
                                                itemid={itemid}
                                            />
                                            <ModalDeleteD
                                                show={modalDeleteShowD}
                                                onHide={() => setModalDeleteShowD(false)}
                                                onUpdat={() => getDevicesIdentification()}
                                                itemid={itemid}
                                            />
                                        </tr>
                                    ))}
                                </>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Settings;