import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ModalCreate from './ModalCreate';
import { MdDeleteForever } from "react-icons/md";
import { FaArrowLeft, FaArrowRight, FaPencil, FaPersonCirclePlus } from "react-icons/fa6";
import ModalEdite from './ModalEdite';
import ModalDelete from './ModalDelete';
import Api from '../../tools/api';
import axios from 'axios';
import LoadingSpinner from '../../tools/elements/LoadingSpinner';
import { useCookies } from 'react-cookie';
import ReactPaginate from 'react-paginate';
import { Toaster } from 'react-hot-toast';

const ManagementUser = () => {
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalEditeShow, setModalEditeShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [itemid, setItem] = useState([]);
    const [cookies] = useCookies(['userData']);
    const [currentPage, setCurrentPage] = useState(0);
    const usersPerPage = 10;

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: cookies.userData.roleId == 1 ? 'Users' : 'Users/getUserByRole' })
            if (response) {
                setUsers(response)
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const pageCount = Math.ceil(users.length / usersPerPage);
    const pagesVisited = currentPage * usersPerPage;

    const displayUsers = users
        .slice(pagesVisited, pagesVisited + usersPerPage)
        .map((item, index) => (
            <tr className='table-tr' key={index}>
                <td className='head-color1'>{item.userId}</td>
                <td className='head-color2'>{item.userName}</td>
                <td className='head-color2'>{item.genderId === 1 ? "ذكر" : "انثى"}</td>
                <td className='head-color2'>{item.roleId === 1 ? "مدير عام"
                    : item.roleId === 2 ? "مشرف" :
                        item.roleId === 3 ? "ممتحن" :
                            "طالب"}</td>
                <td className='head-color1'>
                    <FaPencil onClick={() => setModalEditeShow(true)} /> / <MdDeleteForever onClick={() => {
                        setItem(item)
                        setModalDeleteShow(true);
                    }} /></td>
                <ModalEdite
                    show={modalEditeShow}
                    onHide={() => setModalEditeShow(false)}
                />
                <ModalDelete
                    show={modalDeleteShow}
                    onHide={() => setModalDeleteShow(false)}
                    onUpdat={() => getUsers()}
                    itemid={itemid}
                />
            </tr>
        ));

    return (
        <>
        <Toaster/>
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShow(true)}>
                    <div style={{ padding: '0rem 0rem 0rem 1rem' }}>
                        <FaPersonCirclePlus />
                    </div>
                    اضافة مستخدم
                </Button>
                <ModalCreate
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onUpdat={() => getUsers()}
                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>المعرف</th>
                            <th>الاسم</th>
                            <th>الجنس</th>
                            <th>العمل</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="5"><LoadingSpinner /></td>
                            </tr>
                        ) : users.length === 0 ? (
                            <tr>
                                <td colSpan="5">لا يوجد مشرفين</td>
                            </tr>
                        ) : (
                            displayUsers
                        )}
                    </tbody>
                </table>
                <div className='head-state'>
                    <ReactPaginate
                        previousLabel={<FaArrowRight />}
                        nextLabel={<FaArrowLeft />}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </>
    );
};

export default ManagementUser;
