import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';
import quranKarem from '../../../quran_.json';


const ModalCreate = (props) => {
    const [competitions, setCompetitions] = useState([])
    const [form, setForm] = useState([])
    const [questionClassification, setQuestionClassification] = useState([])
    const [trackType, setTrackType] = useState([])
    const [selectcompetitionId, setselectcompetitionId] = useState(0)
    const [selectformId, setselectformId] = useState(0)
    const [selectquestionClassificationId, setselectquestionClassificationId] = useState(0)
    const [selecttrackTypeId, setselecttrackTypeId] = useState(0)
    const [selectedSurah, setSelectedSurah] = useState(''); // حالة لتخزين السورة المحددة
    const [selectedverse, setSelectedverse] = useState(''); // حالة لتخزين السورة المحددة
    const [verses, setVerses] = useState([]);
    const [verse, setVerse] = useState([]);
    function uniqueSurahWithIds(quranData) {
        const uniqueSurahs = new Map();
        quranData.forEach(sura => {
            if (!uniqueSurahs.has(sura.sura_id)) {
                uniqueSurahs.set(sura.sura_id, {
                    suraName: sura.surah_name,
                    suraId: sura.sura_id
                });
            }
        });
        return Array.from(uniqueSurahs.values());
    }
    const uniqueSurahNamesWithIds = uniqueSurahWithIds(quranKarem.quranKarem);

    const [formData, setFormData] = useState({
        competitionId: 0,
        trackTypeId: 0,
        formId: 0,
        questionId: 0,
        questionMarks: 0,
        surahNo: 0,
        verseNo: 0,
        verseText: "string",
        questionClassificationId: 0,
        verseBeginning: "string",
        verseEnd: "string"
    });
    const handleInputChanges = (event) => {
        setSelectedSurah(event.target.value);

        // استرجاع الآيات المتعلقة بالسورة المحددة
        const versesOfSelectedSurah = quranKarem.quranKarem.filter(sura => sura.sura_id === event.target.value);
        setVerses(versesOfSelectedSurah);
    }
    const handleInputChangev = (event) => {
        setSelectedverse(event.target.value);

        // استرجاع الآيات المتعلقة بالسورة المحددة
        const versesOfSelectedSurah = quranKarem.quranKarem.filter(sura => sura.id === event.target.value);
        setVerse(versesOfSelectedSurah[0]);
        console.log(versesOfSelectedSurah);
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        getCompetitions();
        getForm();
        getClassification();
        gettrackType();
    }, []);

    const getCompetitions = async () => {
        try {
            const response = await Api.fetch({ url: 'Competitions' });
            if (response) {
                setCompetitions(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getForm = async () => {
        try {
            const response = await Api.fetch({ url: 'CompetitionsForms' });
            if (response) {
                setForm(response.competitionsForms)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getClassification = async () => {
        try {
            const response = await Api.fetch({ url: 'QuestionsClassifications/getAllQuestionsClassifications' });
            if (response) {
                setQuestionClassification(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const gettrackType = async () => {
        try {
            const response = await Api.fetch({ url: 'TracksTypes' });
            if (response) {
                setTrackType(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addForm = async () => {
        console.log(formData);
        // try {
        //     const response = await Api.fetch({
        //         url: 'FormsQuestions',
        //         body: formData,
        //         method: 'POST',
        //     });
        //     console.log(response);
        //     if (response.ok) {
        //         console.log("تمت الاضافة بنجاح");
        //         props.onHide();
        //         props.onUpdat();
        //     } else {
        //         const errorBody = await response.json();
        //         console.error('Request failed:', errorBody.title);
        //     }
        // } catch (error) {
        //     console.error('Network error:', error.message);
        // }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">إدخال أسئلة النماذج</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="competitionId">المسابقة</Label>
                                            <select id="competitionId" name="competitionId" className="form-control" value={formData.competitionId} onChange={(e) => {
                                                handleInputChange(e);
                                                setselectcompetitionId(e.target.value);
                                            }}>
                                                <option>اختر المسابقة</option>
                                                {competitions.map((item, index) => (
                                                    <option key={index} value={item.competitionId}>{item.competitionName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="formId">النموذج</Label>
                                            <select id="formId" name="formId" className="form-control" value={formData.formId} onChange={(e) => {
                                                handleInputChange(e);
                                                setselectcompetitionId(e.target.value);
                                            }}>
                                                <option>اختر النموذج</option>
                                                {form.map((item, index) => (
                                                    <option key={index} value={item.FormID}>{item.FormText}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="trackTypeId">المسار</Label>
                                            <select id="trackTypeId" name="trackTypeId" className="form-control" value={formData.trackTypeId} onChange={(e) => {
                                                handleInputChange(e);
                                                setselectcompetitionId(e.target.value);
                                            }}>
                                                <option>اختر المسار</option>
                                                {trackType.map((item, index) => (
                                                    <option key={index} value={item.trackTypeId}>{item.trackTypeName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="questionClassificationId">التصنيف</Label>
                                            <select id="questionClassificationId" name="questionClassificationId" className="form-control" value={formData.questionClassificationId} onChange={(e) => {
                                                handleInputChange(e);
                                                setselectcompetitionId(e.target.value);
                                            }}>
                                                <option>اختر التصنيف</option>
                                                {questionClassification.map((item, index) => (
                                                    <option key={index} value={item.questionClassificationId}>{item.questionClassificationName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="questionId">الرقم</Label>
                                            <Input type="text" className="form-control" id="questionId" name='questionId' placeholder="الرقم" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="questionMarks">العلامة</Label>
                                            <Input type="text" className="form-control" id="questionMarks" name='questionMarks' placeholder="العلامة" onChange={handleInputChange} />
                                        </FormGroup>
                                        {/* <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="surahNo">السورة</Label>
                                            <Input type="text" className="form-control" id="surahNo" name='surahNo' placeholder="السورة" onChange={handleInputChange} />
                                        </FormGroup> */}
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="surahNo">السورة</Label>
                                            <select className="form-control" id="surahNo" name='surahNo' onChange={handleInputChanges}>
                                                <option value="">اختر السورة</option>
                                                {uniqueSurahNamesWithIds.map((sura, index) => (
                                                    <option key={index} value={sura.suraId}>{sura.suraName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        {/* <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="verseNo">رقم الآية</Label>
                                            <Input type="text" className="form-control" id="verseNo" name='verseNo' placeholder="رقم الآية" onChange={handleInputChange} />
                                        </FormGroup> */}
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="surahNo">الآية</Label>
                                            <select className="form-control" id="surahNo" name='surahNo' onChange={handleInputChangev}>
                                                <option value="">اختر الآية</option>
                                                {verses.map((verse, index) => (
                                                    <option key={index} value={verse.id}>{verse.index}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="verseText">نص الآية</Label>
                                            <Input type="text" className="form-control" id="verseText" name='verseText' value={verse.text} placeholder="نص الآية" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="verseBeginning">بداية الآية</Label>
                                            <Input type="text" className="form-control" id="verseBeginning" name='verseBeginning' placeholder="بداية الآية" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="verseEnd">نهاية الآية</Label>
                                            <Input type="text" className="form-control" id="verseEnd" name='verseEnd' placeholder="نهاية الآية" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={addForm}>اضافة</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
