import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/images/logos/logo00.png';
import save from '../../assets/images/landingpage/Asset1.svg'
import save2 from '../../assets/images/landingpage/Asset2.svg'
import save3 from '../../assets/images/landingpage/Layer_1.svg'
import PageQuran from '../../components/Pagequran';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Api from '../../tools/api';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../tools/elements/LoadingSpinner';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ExaminerPage() {
    const currentDate = new Date();
    const islamicDate = currentDate.toLocaleDateString('ar-SA-u-ca-islamic');
    const gregorianDate = currentDate.toLocaleDateString('en-US');
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const [show2, setShow2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isaction, setIsaction] = useState(1);
    const lastClickTime = useRef(0);
    const [cookies, setCookie, removeCookie] = useCookies(['userData']);
    const [cookiestap, removeCookietap] = useCookies(['userTab']);
    const [alldata, setalldata] = useState([]);
    const [sumtotal, setsumtotal] = useState(19);
    const [islod, setislod] = useState();
    const [testmessage, settestmessage] = useState();
    const [dataQustionsMark, setQustionsMark] = useState([0]);
    const [dataCpntrolpanel, setdataControlpanel] = useState([]);
    const [dataformquestion, setdataformquestion] = useState([]);
    const [dataquestion, setdataquestion] = useState({
        pagenum: 1,
    });
    const [marke, setmark] = useState({
        countSaveAlert: 0,
        countSaveWrong: 0,
        countFormationAlert: 0,
        countFormationWrong: 0,
        countIntonationAlert: 0,
        countIntonationWrong: 0,
        performanceMarks: 0
    })
    const [dataQustionsMarks, setQustionsMarks] = useState({
        competitionId: dataformquestion.CompititionID,
        examinerId: dataCpntrolpanel.ExaminerID,
        competitorId: dataformquestion.CompetitorID,
        trackTypeId: dataformquestion.TrackTypeID,
        formId: dataformquestion.FormID,
        questionId: dataformquestion.QuestionID,
        qustionMark: 19,
        date: formattedDate,
        time: formattedTime,
        performanceMarks: isaction
    });
    const initialDataQuestionsDetails = {
        competitionId: null,
        examinerId: null,
        tabletId: cookies.userData.userId,
        competitorId: null,
        trackTypeId: null,
        formId: null,
        questionId: null,
        evaluationCriterionId: 0,
        mistakeTypeId: 0,
        errorsAndWarningsCount: 0,
        date: formattedDate,
        time: formattedTime
    };
    const [dataQuestionsDetails, setQuestionsDetails] = useState({
        11: { ...initialDataQuestionsDetails },
        12: { ...initialDataQuestionsDetails },
        21: { ...initialDataQuestionsDetails },
        22: { ...initialDataQuestionsDetails },
        31: { ...initialDataQuestionsDetails },
        32: { ...initialDataQuestionsDetails },
        42: { ...initialDataQuestionsDetails }
    });
    useEffect(() => {
        getDataControlpanel();
        // getDataformquestion();
        getQustionsMarks();
    }, []);
    useEffect(() => {
        if (!dataformquestion.QuestionID) {
            settestmessage('يرجى الانتظار إلى حين تحديد الطالب')
            setIsLoading(true);
            if (dataformquestion.QuestionID) {
                setIsLoading(false)
            }
        }
    }, [islod]);
    useEffect(() => {
        setTotal();
    }, [marke]);
    const handleClose2 = () => {
        setShow2(false);
        // // console.log('ff');
    }
    const handleShow2 = () => setShow2(true);

    const handleClick = (key) => {
        setmark(prevState => ({
            ...prevState,
            [key]: prevState[key] + 1
        }));
    };
    const handleClick0 = (key) => {
        setmark(prevState => ({
            ...prevState,
            [key]: 0
        }));
    };
    const setTotal = () => {
        let total = 19;
        total -= ((marke.countFormationAlert * 2) + (marke.countFormationWrong * 4) + (marke.countIntonationAlert * 0.5) + (marke.countIntonationWrong * 1) + (marke.countSaveAlert * 1) + (marke.countSaveWrong * 2) + (marke.performanceMarks * 0.5));
        // console.log(total);
        if (total >= 0) {
            setsumtotal(total);
        } else {
            setsumtotal(0);
        }
    };
    const handleClickformark = (count) => {
        setIsaction(count);
        setQustionsMarks({
            competitionId: dataformquestion.CompititionID,
            examinerId: dataCpntrolpanel.ExaminerID,
            competitorId: dataformquestion.CompetitorID,
            trackTypeId: dataformquestion.TrackTypeID,
            formId: dataformquestion.FormID,
            questionId: dataformquestion.QuestionID,
            qustionMark: 19,
            date: formattedDate,
            time: formattedTime,
            performanceMarks: count
        })
    }

    const addQuestionsDetails = (evaluationCriterionId, mistakeTypeId, errorsAndWarningsCount) => {
        setQuestionsDetails(prevQuestionsDetails => ({
            ...prevQuestionsDetails,
            [`${evaluationCriterionId}${mistakeTypeId}`]: {
                ...prevQuestionsDetails[`${evaluationCriterionId}${mistakeTypeId}`],
                evaluationCriterionId: evaluationCriterionId,
                mistakeTypeId: mistakeTypeId,
                errorsAndWarningsCount: errorsAndWarningsCount + 1,
                competitionId: dataformquestion.CompititionID,
                competitorId: dataformquestion.CompetitorID,
                trackTypeId: dataformquestion.TrackTypeID,
                formId: dataformquestion.FormID,
                questionId: dataformquestion.QuestionID,
                examinerId: dataCpntrolpanel.ExaminerID
            }
        }));
    };
    const sendQuestionsDetails = async () => {
        console.log(isaction);
        try {
            setIsLoading(true);
            if (dataformquestion.QuestionID == 5) {
                settestmessage('يرجى الانتظار ريثما يتم تحديد طالب جديد')
            } else {
                settestmessage('يرجى الانتظار ريثما يتم الانتقال الى السؤال التالي')
            }
            console.log(dataQustionsMarks);
            try {
                const response = await Api.fetch({
                    url: 'CompetitorsQustionsMarks/addCompetitorsQustionsMarks',
                    body: dataQustionsMarks,
                    method: 'POST',
                });

                if (response) {
                    // console.log("تمت الاضافة بنجاح");
                } else {
                    const errorBody = await response.json();
                    console.error('Request failed:', errorBody.title);
                }
            } catch (error) {
                console.error('Network error:', error.message);
            }
            const promises = Object.values(dataQuestionsDetails).map(async (item, index) => {
                console.log(item);
                try {

                    if (item.competitorId != null) {
                        // console.log(item);
                        const response = await Api.fetch({
                            url: 'CompetitorsQuestionsDetails/addCompetitorsQuestionsDetails',
                            body: item,
                            method: 'POST',
                        });

                        if (response.ok) {
                            // console.log("تمت الاضافة بنجاح");
                        } else {
                            const errorBody = await response.json();
                            console.error('Request failed:', errorBody.title);
                        }
                    }
                } catch (error) {
                    console.error('Network error:', error.message);
                }
            });

            await Promise.all(promises);
            // setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }

    };
    const getQustionsMarks = async () => {
        // try {
        //     // setIsLoading(true);
        //     // setIsShow(true);
        //     const response = await Api.fetch({ url: `CompetitorsQustionsMarks/getQuestionMarkForExamier?competitionID=4&competitorID=${dataformquestion.CompetitorID}` })
        //     if (response != null) {
        //         console.log(response);
        //         setQustionsMark(response.examinerMarks);
        //         // setIsLoading(false);
        //         // setIsShow(false);
        //     } else {
        //         setQustionsMark({
        //             ExaminerName: "غير محدد"
        //         });
        //     }
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };
    const getDataControlpanel = async () => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitionsControlPanels/getExaminerByTablet?userId=${cookiestap.userTab}&tabletId=${cookies.userData.userId}` })
            if (response.examinerByTablet.length > 0) { // تحقق مما إذا كانت المصفوفة غير فارغة
                console.log(response.examinerByTablet); // استخدم -1 للوصول إلى آخر عنصر
                setdataControlpanel(response.examinerByTablet[0]); // استخدم -1 أيضًا هنا
                setQustionsMarks(prevQustionsMarks => ({
                    ...prevQustionsMarks,
                    'examinerId': response.examinerByTablet[0].ExaminerID
                }));
            } else {
                setdataControlpanel({
                    ExaminerName: "غير محدد"
                });
            }

            // getDataformquestion(response.examinerByTablet[0].ExaminerID);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getDataformquestion = async (data) => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitorsFormsTrackQuestion/getCompetitorIdForExaminer?superciverId=${cookiestap.userTab}` })
            if (response[0] != null) {
                console.log(response[0]);
                setQustionsMarks({
                    competitionId: response[0].CompititionID,
                    examinerId: data,
                    competitorId: response[0].CompetitorID,
                    trackTypeId: response[0].TrackTypeID,
                    formId: response[0].FormID,
                    questionId: response[0].QuestionID,
                    qustionMark: 19,
                    date: formattedDate,
                    time: formattedTime,
                    performanceMarks: isaction
                });
                setdataformquestion(response[0]);
                setislod(response[0].CompetitorID)
                getDataQuestion(response[0]);
                setIsLoading(false);
                getMarksExamier(response[0], data);
            } else {
                setdataformquestion({
                    ExaminerName: "لا يوجد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getMarksExamier = async (data, ExaminerID) => {

        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitorsQustionsMarks/getMarksForExamier?FormID=${data.FormID}&TrackTypeID=${data.TrackTypeID}&CompetitorID=${data.CompetitorID}&ExaminerID=${ExaminerID}&UserID=${data.UserID}` })
            // console.log(response.marksForExamier);
            setQustionsMark(response.marksForExamier)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getDataQuestion = async (data) => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `FormsQuestions/getFormQuestions?compititionId=${data.CompititionID}&trackTypeId=${data.TrackTypeID}&formId=${data.FormID}&questionId=${data.QuestionID}` })
            // console.log(response);
            setdataquestion(response);
            const surahNumber = response.surahNo;
            const verseNumber = response.verseNo;
            // const apiUrl = `https://api.alquran.cloud/ayah/${surahNumber}:${verseNumber}`;

            const responsedata = await axios.get(`https://api.alquran.cloud/v1/ayah/${surahNumber}:${verseNumber}`);
            // console.log(resp onsedata.data.data.page);
            if (dataquestion.pagenum !== responsedata.data.data.page) {
                // console.log(dataquestion.pagenum );
                // console.log(responsedata.data.data.page);
                setdataquestion(dataquestion => ({
                    ...dataquestion,
                    "pagenum": responsedata.data.data.page
                }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const get1 = async () => {
        try {
            const response = await Api.fetch({ url: `CompetitorsFormsTrackQuestion/getCompetitorIdForExaminer?superciverId=${cookiestap.userTab}` })
            // console.log("j "+islod);
            // console.log(response[0].CompetitorID);
            if (response[0].CompetitorID != dataformquestion.CompetitorID || response[0].QuestionID != dataformquestion.QuestionID) {
                // console.log(response[0].CompetitorID);
                // console.log(islod);
                getDataformquestion();
                getDataControlpanel();
                setmark({
                    countSaveAlert: 0,
                    countSaveWrong: 0,
                    countFormationAlert: 0,
                    countFormationWrong: 0,
                    countIntonationAlert: 0,
                    countIntonationWrong: 0,
                    performanceMarks: 0
                });
                setQuestionsDetails({
                    11: { ...initialDataQuestionsDetails },
                    12: { ...initialDataQuestionsDetails },
                    21: { ...initialDataQuestionsDetails },
                    22: { ...initialDataQuestionsDetails },
                    31: { ...initialDataQuestionsDetails },
                    32: { ...initialDataQuestionsDetails },
                    42: { ...initialDataQuestionsDetails }
                });
                setQustionsMarks({
                    competitionId: dataformquestion.CompititionID,
                    examinerId: dataCpntrolpanel.ExaminerID,
                    competitorId: dataformquestion.CompetitorID,
                    trackTypeId: dataformquestion.TrackTypeID,
                    formId: dataformquestion.FormID,
                    questionId: dataformquestion.QuestionID,
                    qustionMark: 19,
                    date: formattedDate,
                    time: formattedTime,
                    performanceMarks: isaction
                })
                getMarksExamier();
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            if (dataquestion.pagenum !== dataquestion.pagenum) {
                console.log('====================================');
                console.log('kkkkkkkkkkkk');
                console.log('====================================');
            }
            get1();
            getDataControlpanel();
            // console.log(dataCpntrolpanel);
            if (dataCpntrolpanel.ExaminerName === "غير محدد") {
                // console.log('kj');
                onLogout();
            }
        }, 2000);

        return () => clearInterval(interval);
    });
    const onLogout = async () => {
        // Accessing the 'userId' from userData cookie
        const userData = cookies['userData'];
        const userTab = cookies['userTab'];
        // console.log(userTab);
        // console.log(userData);
        if (userData && userData.userId) {
            // call api to remove the token
            await Api.fetch({ method: 'POST', url: `Auth/logout?id=${userData.userId}&supervicer=${userTab}` });
        }
        // reset cookie
        removeCookie('userData');
        removeCookietap('userTab');
        window.location.href = '/'
    }
    return (
        <>
            {isLoading ? <LoadingSpinner text={testmessage} /> : ''}
            <div className='exam-page'>
                <div className='exam-head'>
                    <div style={{ textAlign: 'right' }}>
                        <h4>{cookies.userData.userName} <span>:الجهاز</span></h4>
                        <h4> <span>الشيخ:</span> {dataCpntrolpanel.ExaminerName}</h4>
                    </div>
                    <div className='text-center'>
                        <h4>المسابقة القرانية الكبرى ١٤٤٥هـ</h4>
                        <h4>بطاقة تقييم المتسابقين</h4>
                    </div>
                    <div>
                        <Link to={'/'}>
                            <img src={logo} alt="wrapkit" width={75} />
                        </Link>
                    </div>
                </div>
                <div className='exam-data'>
                    <h4> <span>السؤال:</span> 5/{dataformquestion.QuestionID}</h4>
                    <h4>{dataformquestion.CompetitorID}<span>: ID</span></h4>
                    <h4><span>{dataformquestion.GenderID === 1 ? 'الطالب' : 'الطالبة'}</span>: {dataformquestion.FullName}</h4>
                    <h4><span>رقم النموذج:</span> {dataformquestion.FormID}</h4>
                    <h4><span>المسار:</span> {dataformquestion.TrackTypeName}</h4>
                </div>
                <div className='exam-body'>
                    <div className='exam-mark'>
                        <div className='exam-card'>
                            <div className='text-center'>
                                <h4 onDoubleClick={() => {
                                    addQuestionsDetails(1, 2, -1);
                                    handleClick0("countSaveAlert");
                                }}>تنبيه</h4>
                                <div className='exam-circal' onClick={() => {
                                    addQuestionsDetails(1, 2, marke.countSaveAlert);
                                    handleClick("countSaveAlert");
                                }}>
                                    <span>{marke.countSaveAlert}</span>
                                </div>
                            </div>
                            <div className='text-center' >
                                <h4 onDoubleClick={() => {
                                    addQuestionsDetails(1, 1, -1);
                                    handleClick0("countSaveWrong");
                                }}>خطا</h4>
                                <div className='exam-circal' onClick={() => {
                                    handleClick("countSaveWrong");
                                    addQuestionsDetails(1, 1, marke.countSaveWrong);
                                }}>
                                    <span>{marke.countSaveWrong}</span>
                                </div>
                            </div>
                            <div className='text-center exam-card-icon'>
                                <h4>الحفظ</h4>
                                <img src={save3} alt="wrapkit" width={60} />
                            </div>
                        </div>
                        <div className='exam-card'>
                            <div className='text-center exam-card-icon'>
                                <h4>التشكيل</h4>
                                <img src={save2} alt="wrapkit" width={60} />
                            </div>
                            <div className='text-center'>
                                <h4 onDoubleClick={() => {
                                    addQuestionsDetails(2, 2, -1);
                                    handleClick0("countFormationAlert");
                                }}>تنبيه</h4>
                                <div className='exam-circal' onClick={() => {
                                    handleClick("countFormationAlert");
                                    addQuestionsDetails(2, 2, marke.countFormationAlert);
                                }}>
                                    <span>{marke.countFormationAlert}</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h4 onDoubleClick={() => {
                                    addQuestionsDetails(2, 1, -1);
                                    handleClick0("countFormationWrong");
                                }}>خطا</h4>
                                <div className='exam-circal' onClick={() => {
                                    handleClick("countFormationWrong");
                                    addQuestionsDetails(2, 1, marke.countFormationWrong);
                                }}>
                                    <span>{marke.countFormationWrong}</span>
                                </div>
                            </div>
                        </div>
                        <div className='exam-card'>
                            <div className='text-center'>
                                <h5 style={{ width: '96px' }} onDoubleClick={() => {
                                    addQuestionsDetails(3, 1, -1);
                                    handleClick0("countIntonationWrong");
                                }}>النون والمدود</h5>
                                <div className='exam-circal' onClick={() => {
                                    handleClick("countIntonationWrong");
                                    addQuestionsDetails(3, 1, marke.countIntonationWrong);
                                }}>
                                    <span>{marke.countIntonationWrong}</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h5 style={{ width: '96px' }} onDoubleClick={() => {
                                    addQuestionsDetails(3, 2, -1);
                                    handleClick0("countIntonationAlert");
                                }}>المخارج والصفات</h5>
                                <div className='exam-circal' onClick={() => {
                                    handleClick("countIntonationAlert");
                                    addQuestionsDetails(3, 2, marke.countIntonationAlert);
                                }}>
                                    <span>{marke.countIntonationAlert}</span>
                                </div>
                            </div>
                            <div className='text-center exam-card-icon'>
                                <h4>التجويد</h4>
                                <img src={save} alt="wrapkit" width={60} />
                            </div>
                        </div>
                        <div className='exam-card'>
                            <h4 onDoubleClick={() => {
                                addQuestionsDetails(4, 2, -1);
                                handleClick0("performanceMarks");
                            }} className='exam-h4'>التردد</h4>
                            <div className='exam-sq' onClick={() => {
                                handleClick("performanceMarks");
                                addQuestionsDetails(4, 2, marke.performanceMarks);
                            }}>
                                <span>{marke.performanceMarks}</span>
                            </div>
                        </div>
                        <div className='exam-card'>
                            <h4 className='exam-h4'>علامة السؤال</h4>
                            <h4 className={sumtotal < 8.5 ? 'red-color' : ''}>19/{sumtotal}</h4>
                        </div>
                        <div className='exam-card'>
                            <h4 className='exam-h4'>الأداء:</h4>
                            <h4 onClick={() => handleClickformark(1)} className={isaction == 1 ? "action" : ""}>1</h4>
                            <h4 onClick={() => handleClickformark(2)} className={isaction == 2 ? "action" : ""}>2</h4>
                            <h4 onClick={() => handleClickformark(3)} className={isaction == 3 ? "action" : ""}>3</h4>
                            <h4 onClick={() => handleClickformark(4)} className={isaction == 4 ? "action" : ""}>4</h4>
                            <h4 onClick={() => handleClickformark(5)} className={isaction == 5 ? "action" : ""}>5</h4>
                        </div>
                    </div>
                    <div className='exam-quran'>
                        <div className='card-wiew1'>
                            <h6 style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'white' }}>
                                أكمل من قوله تعالى: {dataquestion.verseBeginning}......
                            </h6>
                        </div>
                        <div className='card-wiew2'>
                            <h6 style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                إلى قوله تعالى: {dataquestion.verseEnd}
                            </h6>
                        </div>
                        <PageQuran page={dataquestion.pagenum} />
                    </div>
                    <div className='exam-record'>
                        <div className="exam-record-card" dir='rtl'>
                            <img src={logo} alt="wrapkit" width={96} />
                            <h2>سجل الدرجات </h2>
                            <table>
                                <thead>
                                    <tr>
                                        <th>رقم السؤال</th>
                                        <th>الدرجة</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataQustionsMark.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.QuestionID}</td>
                                            <td>{item.QustionMark}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            <Button className='btn-send' onClick={() => handleShow2()}>
                                رفع البيانات
                            </Button>
                            <Modal
                                show={show2}
                                onHide={handleClose2}
                                backdrop="static"
                                keyboard={false}
                                dir="rtl"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>رسالة تاكيد</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    هل انت متأكد من رفع البيانات
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose2}>
                                        الغاء
                                    </Button>
                                    <Button onClick={() => {
                                        sendQuestionsDetails();
                                        handleClose2();
                                    }} >نعم</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                {/* <div className='exam-foter'>
                    <div className='exam-card'>
                        <h4 className='exam-h4'>الأداء:</h4>
                        <h4 onClick={() => handleClickformark(1)} className={dataQustionsMarks.performanceMarks == 1 ? "action" : ""}>1</h4>
                        <h4 onClick={() => handleClickformark(2)} className={dataQustionsMarks.performanceMarks == 2 ? "action" : ""}>2</h4>
                        <h4 onClick={() => handleClickformark(3)} className={dataQustionsMarks.performanceMarks == 3 ? "action" : ""}>3</h4>
                        <h4 onClick={() => handleClickformark(4)} className={dataQustionsMarks.performanceMarks == 4 ? "action" : ""}>4</h4>
                        <h4 onClick={() => handleClickformark(5)} className={dataQustionsMarks.performanceMarks == 5 ? "action" : ""}>5</h4>
                    </div>
                    <div className='exam-data'>
                        <h4>: الملاحظات</h4>
                        <input type='text' placeholder='ملاحظات' />
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default ExaminerPage;