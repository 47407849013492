import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';

const ModalCreate = (props) => {
    const [competitions, setCompetitions] = useState([])

    const [formData, setFormData] = useState({
        committeeId: 0,
        competitionId: 0,
        tabletsNo: 0
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        getCompetitions();
    }, []);

    const getCompetitions = async () => {
        try {
            const response = await Api.fetch({ url: 'Competitions' })
            if (response) {
                console.log(response);
                setCompetitions(response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const addSettings = async () => {
        try {
            console.log(formData);
            const response = await Api.fetch({
                url: 'CompetitionsSettings/addCompetitionSettings',
                body: formData,
                method: 'POST',
            });

            if (response) {
                console.log("تمت الاضافة بنجاح");
                props.onHide();
                props.onUpdat();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">اعدادات المسابقة</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="competitionId">المسابقة</Label>
                                            <select id="competitionId" name="competitionId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                            }}>
                                                <option>اختر المسابقة</option>
                                                {competitions.map((item, index) => (
                                                    <option key={index} value={item.competitionId}>{item.competitionName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="tabletsNo">عدد الاجهزة</Label>
                                            <Input type="text" className="form-control" id="tabletsNo" name='tabletsNo' placeholder="عدد الاجهزة" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={addSettings}>اضافة</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
