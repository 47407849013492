import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';
import Api from '../../tools/api';
import toast from 'react-hot-toast';

const HeaderBanner = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['userData']);
    const [cookiestap, setCookietap, removeCookietap] = useCookies(['userTab']);
    const [dataCpntrolpanel, setdataControlpanel] = useState([]);
    useEffect(() => {
        getDataControlpanel();
        // console.log(cookies.userData);
        // console.log(cookiestap.userTab);
    }, []);
    const getDataControlpanel = async () => {
        try {
            const response = await Api.fetch({ url: `CompetitionsControlPanels/getExaminerByTablet?userId=${cookiestap.userTab}&tabletId=${cookies.userData.userId}` })
            if (response.examinerByTablet[0] != null) {
                console.log(response.examinerByTablet[0]);
                setdataControlpanel(response.examinerByTablet[0]);
            } else {
                setdataControlpanel({
                    ExaminerName: "غير محدد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            getDataControlpanel();
        }, 2000); // ينفذ كل ثانيتين (2000 ميلي ثانية)

        // يتم تنظيف الفاصل الزمني عندما يتم إلغاء تحميل المكون
        return () => clearInterval(interval);
    }, []); // الوصول للقائمة فقط عندما يتم تحميل المكون للمرة الأولى
    const showmessege = () => {
        toast.error('يرجى انتظار تحديد الشيخ');
    }
    return (
        <div className="static-slider-head">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" md="6" className="align-self-center text-center">
                        <h1 className="title">المسابقات القرآنية الكبرى</h1>
                        <h4 className="subtitle font-light">موقع يختص في المسابقات القرانية الكبرى التي تقيمها<br /> وزارة الاوقاف للدعوة والارشاد</h4>
                        {cookies.userData?.roleId == 2? (
                            <Link to="/adminpage" className="btn btn-md m-t-30 btn-success-gradiant font-14">إبدا العمل  {cookies.userData.userName}</Link>
                        ) : (
                            cookies.userData?.roleId == 3 ? (
                                dataCpntrolpanel.ExaminerScreenStatusID ==  3? (
                                    <Link to="/examinerpage" className="btn btn-md m-t-30 btn-success-gradiant font-14">إبدا العمل {cookies.userData.userName}</Link>
                                ) : (
                                    <Link className="btn btn-md m-t-30 btn-success-gradiant font-14" onClick={showmessege}>يرجى الانتظار {cookies.userData.userName}</Link>
                                )
                            ) : (
                                cookies.userData?.roleId == 4 ?
                                    <Link to="/studentpage" className="btn btn-md m-t-30 btn-success-gradiant font-14">إبدا العمل {cookies.userData.userName}</Link>
                                    :
                                    ""
                            )
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
