import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ModalCreate from './ModalCreate'
import { MdDeleteForever } from "react-icons/md";
import { FaArrowLeft, FaArrowRight, FaPencil, FaPersonCirclePlus } from "react-icons/fa6";
import ModalEdite from './ModalEdite';
import ModalDelete from './ModalDelete';
import Api from '../../../tools/api';
import LoadingSpinner from '../../../tools/elements/LoadingSpinner';
import ReactPaginate from 'react-paginate';
import { Toaster } from 'react-hot-toast';

const Centers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalEditeShow, setModalEditeShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [itemid, setItem] = useState([])
    const [center, setCenter] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const itemPerPage = 10;

    useEffect(() => {
        getCenters();
    }, []);
    const getCenters = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: 'Centers/getCenters' })
            if (response) {
                console.log(response);
                setCenter(response);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const pageCount = Math.ceil(center.length / itemPerPage);
    const pagesVisited = currentPage * itemPerPage;

    const displaycenter = center
        .slice(pagesVisited, pagesVisited + itemPerPage)
        .map((item, index) => (
            <tr className='table-tr'>
                <td className='head-color1'>{item.CenterID}</td>
                <td className='head-color2'>{item.DirectorateIName}</td>
                <td className='head-color2'>{item.CenterName}</td>
                <td className='head-color1'>
                    <MdDeleteForever onClick={() => {
                        setItem(item)
                        setModalDeleteShow(true);
                    }} /> / <FaPencil onClick={() => {
                        // setItem(item);
                        // setModalEditeShow(true)
                    }} /></td>
                <ModalDelete
                    show={modalDeleteShow}
                    onHide={() => setModalDeleteShow(false)}
                    onUpdat={() => getCenters()}
                    itemid={itemid}
                />
            </tr>
        ));
    return (
        <>
        <Toaster />
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShow(true)}>
                    <div style={{ padding: '0rem 0rem 0rem 1rem' }}>
                        <FaPersonCirclePlus />
                    </div>
                    اضافة مركز تحفيظ
                </Button>
                <ModalCreate
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onUpdat={() => getCenters()}

                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>الرقم</th>
                            <th>اسم المديرية</th>
                            <th>اسم المركز</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            center == null ? (
                                <div>لا يوجد مشرفين</div>
                            ) : (
                                displaycenter
                            )
                        )}
                    </tbody>
                </table>
                <div className='head-state'>
                    <ReactPaginate
                        previousLabel={<FaArrowRight />}
                        nextLabel={<FaArrowLeft />}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </>
    );
}

export default Centers;