import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logos/logo00.png';
import PageQuran from '../../components/Pagequran';
import { Form } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from '../../tools/api';
import { Link } from 'react-router-dom';
import { default as LoadingAnimation } from '../../lottie/loading.json';
import LoadingSpinner from '../../tools/elements/LoadingSpinner';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const formatDate = (islamicDate, gregorianDate) => {
    const islamicDateParts = islamicDate.split('/');
    const islamicDay = islamicDateParts[0];
    const islamicMonth = islamicDateParts[1];
    const islamicYear = islamicDateParts.length >= 3 ? islamicDateParts[2].slice(0, -2) : '';

    // Splitting the Gregorian date to extract day, month, and year
    const gregorianDateParts = gregorianDate.split('/');
    const gregorianDay = gregorianDateParts[0];
    const gregorianMonth = gregorianDateParts[1];
    const gregorianYear = gregorianDateParts[2];

    // Return JSX to display the formatted dates
    return (
        <div style={{ textAlign: 'right' }}>
            <h4>{`${islamicDay} / ${islamicMonth} / ${islamicYear}هـ`}</h4>
            <h4>{`الموافق ${gregorianDay} / ${gregorianMonth} / ${gregorianYear} م`}</h4>
        </div>
    );
}
function AdminPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [cookies] = useCookies(['userData']);
    const [cookiestap] = useCookies(['userTab']);
    const [competitorID, setcompetitorID] = useState();
    const [TotalMark, setTotalMark] = useState(0);
    const [dataCompetitors, setCompetitors] = useState([]);
    const [dataControlPanel, setControlPanel] = useState([]);
    const [dataQustionsMarks, setQustionsMarks] = useState([
        {
            1: null,
            2: null,
            3: null,
            4: null,
            5: null,
            ExaminerName: ""
        }
    ]);
    const [dataAllCompetitor, setAllCompetitor] = useState([]);
    const [arrayDataqustiom, setarrayDataqustiom] = useState([]);
    const [dataCompetitor, setCompetitor] = useState({
        CompetitionID: null,
        CompetitorID: null,
        Date: null,
        ExaminerID: null,
        ExaminerName: "",
        FatherName: "",
        FirstName: "",
        FormID: null,
        FormText: "",
        LastName: "",
        PerformanceMarks: null,
        Time: "",
        TrackTypeID: null,
        TrackTypeName: ""
    });
    const currentDate = new Date();
    // Formatting current date to Islamic and Gregorian calendars
    const islamicDate = currentDate.toLocaleDateString('ar-SA-u-ca-islamic');
    const gregorianDate = currentDate.toLocaleDateString('en-US');
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [item, setitem] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => {
        setShow1(true);
        setitem(item);
    };
    const [formData, setFormData] = useState({
        competitionId: 1,
        userId: cookies.userData.userId,
        tabletId: null,
        examinerId: 0,
        sessionId: 1,
        date: formattedDate,
        examinerScreenStatusId: 1,
        examinerStatusId: 0
    });
    const [formDataTrack, setFormDataTrack] = useState({
        compititionId: 0,
        trackTypeId: 1,
        competitorId: 0,
        userId: 0,
        date: "",
        time: "",
        formId: 0,
        questionId: 1
    });
    const handleInputChange = async (item, e) => {
        // console.log(item);
        const { name, value } = e.target;
        setFormData({
            competitionId: item.CompetitionID,
            userId: cookies.userData.userId,
            tabletId: item.TabletID,
            examinerId: value,
            sessionId: item.SessionID,
            date: item.Date,
            examinerScreenStatusId: 3,
            examinerStatusId: 1
        });
    }
    const handleInputChangeId = async (e) => {
        const { name, value } = e.target;
        setcompetitorID(value);
        setFormDataTrack({
            compititionId: 1,
            trackTypeId: 1,
            competitorId: value,
            userId: cookies.userData.userId,
            date: formattedDate,
            time: formattedTime,
            formId: 0,
            questionId: 1
        });
        console.log('====================================');
        console.log(formattedTime);
        console.log('====================================');
    }
    useEffect(() => {
        aa();
        getCompetitors();
        getControlPanel();
        getallCompetitors();
        // getQustionsMarks();
    }, []);
    // useEffect(() => {
    //     getQustionsMarks();
    // }, [competitorID]);
    const getCompetitors = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: `Committees/getAllCommites` })
            if (response != null) {
                // console.log(response);
                setCompetitors(response);
                setIsLoading(false);
            } else {
                setCompetitors({
                    ExaminerName: "غير محدد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getQustionsMarks = async (id) => {
        // console.log(id);
        try {
            const response = await Api.fetch({ url: `CompetitorsQustionsMarks/getQuestionMarkForExamier?competitionID=${id.CompititionID}&competitorID=${id.CompetitorID}` })
            // console.log(response);
            if (response.examinerMarks != dataQustionsMarks) {
                setQustionsMarks(response.examinerMarks);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getControlPanel = async () => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitionsControlPanels/getControlPanelByUserAdmın?userId=${cookies.userData.userId}` })
            if (response != null) {
                // console.log(response.controlPanelNew);
                setControlPanel(response.controlPanelNew);
                // setIsLoading(false);
            } else {
                setControlPanel({
                    ExaminerName: "غير محدد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getTotalMark = async (data) => {
        try {
            const response = await Api.fetch({ url: `CompetitorsQustionsMarks/getTotalMarksForExaminer?competitionID=${data.CompititionID}&competitorID=${data.CompetitorID}` })
            // console.log(response.examinerMarks[0]);
            setTotalMark(response.examinerMarks[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            getControlPanel();
            // getQustionsMarks();
            aa();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const getallCompetitors = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitorsInfoes/getCompetitorsInfoes` })
            if (response != null) {
                // console.log(response);
                setAllCompetitor(response);
                setIsLoading(false);
            } else {
                setAllCompetitor({
                    ExaminerName: "غير محدد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getCompetitor = async () => {
        // Check if competitorID is defined and not null
        if (competitorID != null) {
            // Trim competitorID and check if it's empty
            const trimmedCompetitorID = competitorID.trim();
            if (!trimmedCompetitorID) {
                // If competitorID is empty, return early without executing further code
                return;
            }

            // console.log(trimmedCompetitorID);
            try {
                // setIsLoading(true);
                const response = await Api.fetch({ url: `CompetitorsQustionsMarks/getDataQuestionMarkForExamier?competitionID=4&competitorID=${trimmedCompetitorID}` })
                if (response != null) {
                    // console.log(response.dataExaminerMarks[0]);
                    setCompetitor(response.dataExaminerMarks[0]);
                    // setIsLoading(false);
                } else {
                    setCompetitor({
                        ExaminerName: "غير محدد"
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    const addControlPanel = async () => {
        // console.log(formData);
        if (formData.examinerId == 0) {
            toast.error('برجى اختيار الشيخ')
        } else {
            try {
                const response = await Api.fetch({
                    url: `CompetitionsControlPanels/editCompetitionControlPanels?competitionId=${formData.competitionId}&tabletId=${formData.tabletId}&userId=${formData.userId}`,
                    body: formData,
                    method: 'PUT',
                });
                // console.log(response);
                if (response.ok) {
                    toast.success("تمت اضافة الشيخ بنجاح");
                    getControlPanel();
                } else {
                    const errorBody = await response.json();
                    console.error('Request failed:', errorBody.title);
                }
            } catch (error) {
                toast.error("لا يمكن اضافة الشيخ مرة ثانية");
                console.error('Network error:', error);
            }
            setFormData({
                competitionId: 4,
                userId: cookies.userData.userId,
                tabletId: null,
                examinerId: 0,
                sessionId: 1,
                date: formattedDate,
                examinerScreenStatusId: 1,
                examinerStatusId: 0
            });
        }
    }

    const addFormsTrack = async () => {
        console.log(formDataTrack);
        try {
            const response = await Api.fetch({
                url: `CompetitorsFormsTrackQuestion/addCompetitorsFormsTrackQuestions`,
                body: formDataTrack,
                method: 'POST',
            });
            console.log(response);
            if (response.ok) {
                toast.success("تمت الاضافة بنجاح");
                window.location.reload();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            toast.error("لا يوجد نماذج متاحة لهذه المسابقة");
            console.error('Network error:', error.message);
            setTimeout(() => {
                window.location.reload();
              }, 750); 
        }
        aa();
        setcompetitorID(formDataTrack.competitorId);
        setFormDataTrack({
            compititionId: 0,
            trackTypeId: 0,
            competitorId: 0,
            userId: 0,
            date: formattedDate,
            time: formattedTime,
            formId: 0,
            questionId: 1
        });
    }
    const aa = async () => {
        const response = Api.fetch({ url: `CompetitorsFormsTrackQuestion/getCompetitorIdForExaminer?superciverId=${cookiestap.userTab}` })
        response.then(data => {
            if (data[0] == null) {
                getCompetitor();
            } else {
                // Accessing the data from the PromiseResult
                const dataArray = data[0];
                // console.log(dataArray);
                setCompetitor(dataArray);
                setarrayDataqustiom(
                    {
                        compititionId: dataArray.CompititionID,
                        trackTypeId: dataArray.TrackTypeID,
                        competitorId: dataArray.CompetitorID,
                        userId: cookies.userData.userId,
                        date: formattedDate,
                        time: formattedTime,
                        formId: dataArray.FormID,
                        questionId: dataArray.QuestionID + 1
                    });
                getQustionsMarks(dataArray);
                getTotalMark(dataArray);
            }
        });
    }
    const logout = async () => {
        if (item) {
            // console.log(item);
            try {
                await Api.fetch({ method: 'POST', url: `Auth/logout?id=${item.TabID}&supervicer=${item.UserID}` });
                toast.success(' :تم تسجيل خروج الشيخ'.FullName)
            } catch (error) {
                toast.error("لقد تم اختبار الطالب مسبقا");
                console.error('Network error:', error.message);
            }
        }

    };
    const nextquestion = async () => {
        if (arrayDataqustiom.questionId < 6) {
            console.log(arrayDataqustiom);
            try {
                const response = await Api.fetch({
                    url: `CompetitorsFormsTrackQuestion/editCompetitorsFormsTrackQuestions?compititionId=${arrayDataqustiom.compititionId}&trackTypeId=${arrayDataqustiom.trackTypeId}&competitorId=${arrayDataqustiom.competitorId}`,
                    body: arrayDataqustiom,
                    method: 'PUT',
                });
                console.log(response);
                if (response.ok) {
                    toast.success("تم الانتقال بنجاح");
                    
                    // aa();
                } else {
                    const errorBody = await response.json();
                    console.error('Request failed:', errorBody);
                }
            } catch (error) {
                toast.error("يرجى التاكد من ادخال العلامات");
                console.error('Network error:', error.message);
            }

        } else {
            toast.error("انتهت الاسئلة لهذا النموذج")
        }
    };

    return (
        <>
            <Toaster />
            <div className='exam-page'>
                <div className='exam-head'>
                    {/* {formatDate(islamicDate, gregorianDate)} */}
                    <div style={{ textAlign: 'right' }}>
                        <h4><span>الشيخ: </span>{cookies.userData.userName} </h4>
                        <h4> <span>الجهاز :</span> {cookies.userData.userId}</h4>
                    </div>
                    <div className='text-center'>
                        <h2>المسابقة القرانية الكبرى</h2>
                        <h2>هـ١٤٤٥</h2>
                        <h2>لوحة المشرف</h2>
                    </div>
                    <div>
                        <Link to={'/'}>
                            <img src={logo} alt="wrapkit" width={125} />
                        </Link>
                    </div>
                </div>
                <div className='body-dash-mangment' dir='rtl'>
                    <table className='dash-table'>
                        <thead>
                            <tr className='head'>
                                <th style={{ borderTopRightRadius: 16 + 'px' }}>الرقم</th>
                                <th>الجهاز</th>
                                <th>الشيخ</th>
                                <th>تعيين</th>
                                <th>حالة المستخدم</th>
                                <th style={{ borderTopLeftRadius: 16 + 'px' }}>خروج</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                                dataControlPanel.length == 0 ? (
                                    <div>لا يوجد معايير</div>
                                ) : (
                                    <>
                                        {dataControlPanel.map((item, index) => (
                                            <tr className='table-tr'>
                                                <td className='head-color1'>{item.TabID}</td>
                                                <td className='head-color2'>{item.TabletName}</td>
                                                <td className='head-color2'>
                                                    <select
                                                        id={"examinerId" + index}
                                                        name={"examinerId" + index}
                                                        value={item.ExaminerID}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            handleInputChange(item, e);
                                                        }}
                                                    >
                                                        <option value={0}>اختر الشيخ</option>
                                                        {dataCompetitors.map((competitor, index) => (
                                                            <option key={index} value={competitor.examinerId}>
                                                                {competitor.genderId === 1 ? 'الشيخ' : 'الأخت'} : {competitor.examinerName}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </td>
                                                <td className='head-color2'>
                                                    <Form>
                                                        <Form.Check type='checkbox' id={`check-api-checkbox`}>
                                                            <Form.Check.Input type='checkbox' isValid checked={item.ExaminerStatusID === 1} onClick={addControlPanel} />
                                                        </Form.Check>
                                                    </Form>
                                                </td>
                                                <td className='head-state'>
                                                    <div>
                                                        <span style={{ backgroundColor: item.ExaminerScreenStatusID === 2 || item.ExaminerScreenStatusID === 1 ? '#FFC107' : "" }}></span>
                                                        انتظار
                                                    </div>
                                                    <div>
                                                        <span style={{ backgroundColor: item.ExaminerScreenStatusID === 3 ? '#4CAF50' : "" }}></span>
                                                        فعال
                                                    </div>
                                                </td>
                                                <td className='head-color1' >
                                                    <svg onClick={() => { handleShow1(item); }} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.36 14.3C15.4995 14.4411 15.5777 14.6316 15.5777 14.83C15.5777 15.0284 15.4995 15.2189 15.36 15.36C15.2905 15.4298 15.2079 15.4852 15.117 15.5229C15.026 15.5607 14.9285 15.5802 14.83 15.5802C14.7315 15.5802 14.634 15.5607 14.543 15.5229C14.4521 15.4852 14.3695 15.4298 14.3 15.36L12 13.06L9.7 15.36C9.6305 15.4298 9.54791 15.4852 9.45696 15.5229C9.366 15.5607 9.26849 15.5802 9.17 15.5802C9.07151 15.5802 8.974 15.5607 8.88304 15.5229C8.79209 15.4852 8.7095 15.4298 8.64 15.36C8.50052 15.2189 8.4223 15.0284 8.4223 14.83C8.4223 14.6316 8.50052 14.4411 8.64 14.3L10.94 12L8.64 9.7C8.50752 9.55783 8.4354 9.36978 8.43883 9.17548C8.44225 8.98118 8.52097 8.79579 8.65838 8.65838C8.79579 8.52097 8.98118 8.44225 9.17548 8.43883C9.36978 8.4354 9.55783 8.50752 9.7 8.64L12 10.94L14.3 8.64C14.4422 8.50752 14.6302 8.4354 14.8245 8.43883C15.0188 8.44225 15.2042 8.52097 15.3416 8.65838C15.479 8.79579 15.5577 8.98118 15.5612 9.17548C15.5646 9.36978 15.4925 9.55783 15.36 9.7L13.06 12L15.36 14.3Z" fill="#EA5148" />
                                                    </svg>
                                                    <Modal
                                                        show={show1}
                                                        onHide={handleClose1}
                                                        backdrop="static"
                                                        keyboard={false}
                                                        dir="rtl"
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>رسالة تاكيد</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>

                                                            هل انت متأكد من تسجيل خروح المستخدم
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleClose1}>
                                                                الغاء
                                                            </Button>
                                                            <Button onClick={() => {
                                                                logout();
                                                                handleClose1();
                                                            }} >نعم</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                    {/* <Form>
                                                        <Form.Check type='checkbox' id={`check-api-checkbox`}>
                                                            <Form.Check.Input type='checkbox' isValid checked={item.ExaminerStatusID == 1} onClick={() => { logout(item.UserID, item.TabID, item.FullName) }} />
                                                        </Form.Check>
                                                    </Form> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='exam-data'>
                    <div className='exam-data'>
                        {/* <select className="form-control" id="surahNo" name='surahNo' value={dataCompetitor.CompetitorID} style={{ width: "42%" }} onChange={(e) => { handleInputChangeId(e) }}>
                            <option value="">معرف الطالب:</option>
                            {dataAllCompetitor.map((item, index) => (
                                <option key={index} value={item.CompetitorID}>{item.FullName}</option>
                            ))}
                        </select> */}
                        <input
                            type="text"
                            className="form-control"
                            id="surahNo"
                            name="surahNo"
                            value={competitorID}
                            style={{ width: "42%" }}
                            onChange={(e) => { handleInputChangeId(e) }}
                            placeholder="معرف الطالب"
                        />
                        <button className='btn-send' onClick={addFormsTrack}>اختيار الطالب</button>
                    </div>
                    <h4>{dataCompetitor ? dataCompetitor.CompetitorID: "...."}<span>: ID</span></h4>
                    <h4>{dataCompetitor.GenderID === 1 ? 'الطالب' : 'الطالبة'}: {dataCompetitor ? dataCompetitor.FullName : "..."}</h4>
                    <h4>المسار: {dataCompetitor ? dataCompetitor.TrackTypeName : "..."}</h4>
                    <h4>رقم النموذج: {dataCompetitor ? dataCompetitor.FormID : "..."}</h4>
                </div>
                <div className='admin-data'>
                    <table className='dash-table' dir='rtl'>
                        <thead>
                            <tr className='head'>
                                <th style={{ borderTopRightRadius: 16 + 'px' }}>الشيخ</th>
                                <th>الجهاز</th>
                                <th className={dataCompetitor.QuestionID === 1 ? 'Qred' : ''}>السؤال 1</th>
                                <th className={dataCompetitor.QuestionID === 2 ? 'Qred' : ''}>السؤال 2</th>
                                <th className={dataCompetitor.QuestionID === 3 ? 'Qred' : ''}>السؤال 3</th>
                                <th className={dataCompetitor.QuestionID === 4 ? 'Qred' : ''}>السؤال 4</th>
                                <th className={dataCompetitor.QuestionID === 5 ? 'Qred' : ''}>السؤال 5</th>
                                <th style={{ borderTopLeftRadius: 16 + 'px' }}>الاداء</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                                isShow ? (
                                    <div>لا يوجد معايير</div>
                                ) : (
                                    <>
                                        {dataQustionsMarks.map((item, index) => (
                                            <tr className='table-tr' key={index}>
                                                {item.ExaminerName ? (
                                                    <>
                                                        <td className='head-color1'>{item.ExaminerName}</td>
                                                        <td className='head-color1'>{item.TabletName}</td>
                                                    </>
                                                ) : (
                                                    <td className='head-color1' colSpan="2">{item.TabletName}</td>
                                                )}

                                                <td className='head-color2'>{item[1]}</td>
                                                <td className='head-color2'>{item[2]}</td>
                                                <td className='head-color2'>{item[3]}</td>
                                                <td className='head-color2'>{item[4]}</td>
                                                <td className='head-color2'>{item[5]}</td>
                                                <td className='head-color2'>{item.PerformanceMarks}</td>
                                            </tr>
                                        ))}
                                    </>
                                )
                            )}

                        </tbody>
                    </table>
                    <div>
                        <h4 style={{ textAlign: "center" }}>العلامة النهائية: 100/{TotalMark.TotalSum}</h4>
                        <div className='admin-data'>
                            <Button className='btn-warning' onClick={handleShow}>
                                الانتقال للسؤال التالي
                            </Button>
                            <Modal
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                                dir="rtl"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>رسالة تاكيد</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    هل انت متأكد من الانتقال الى السؤال التالي
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        الغاء
                                    </Button>
                                    <Button onClick={() => {
                                        nextquestion();
                                        handleClose();
                                    }} >نعم</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminPage;