import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";
import toast, { Toaster } from 'react-hot-toast';

const notify = () => {
    toast('Hello World');
    // toast.success('Successfully created!');
    // toast.error('This is an error!');
    // toast.custom(<div>Hello World</div>);
    // toast.loading('Waiting...');
};


const Components = () => {

    return (
        <div id="main-wrapper" dir="rtl">
            <Toaster />
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner />
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
