import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';

const ModalEdite = (props) => {
    const [formData, setFormData] = useState([]);
    useEffect(() => {
        setFormData(props.itemid);
    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const editDirctorate = async () => {
        try {
            // const response = await Api.fetch({
            //     url: `/Directorates/${props.itemid.directorateId}`,
            //     body: formData,
            //     method: 'PUT',
            // });
            console.log(formData);
            // if (response.status === 204) {
            //     console.log("تمت الاضافة بنجاح");
            //     props.onHide();
            //     props.onUpdate();
            // } else {
            //     const errorBody = await response.json();
            //     console.error('Request failed:', errorBody.title);
            // }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم التعديل'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">تعديل مشرفين</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="directorateId">رقم المديرية</Label>
                                            <Input type="text" className="form-control" id="directorateId" name='directorateId' value={formData.directorateId} onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="directorateIname">الاسم المديرية</Label>
                                            <Input type="text" className="form-control" id="directorateIname" name='directorateIname' value={formData.directorateIname} onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={editDirctorate}>تعديل</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalEdite;
