import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

const ModalCreate = (props) => {
    const [genders, setGenders] = useState([])
    const [selectgenders, setselectGenders] = useState(0)
    const [formDatas, setFormDatas] = useState({
        committeeId: 0,
        competitionId: 0,
        tabletsNo: 0
    });
    const [formData, setFormData] = useState({
        competitionName: "string",
        startDate: '',
        endDate: "2050-12-15",
        competitionQuestionMarks: null
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleInputChanges = (e) => {
        const { name, value } = e.target;
        setFormDatas({
            ...formDatas,
            [name]: value
        });
    };
    useEffect(() => {
    }, []);


    const addDirctorate = async () => {
        try {
            console.log(formData);
            // const response = await Api.fetch({
            //     url: 'Competitions',
            //     body: formData,
            //     method: 'POST',
            // });

            // if (response) {
            //     toast.success("تمت الاضافة بنجاح");
            //     props.onHide();
            //     props.onUpdat();
            // } else {
            //     const errorBody = await response.json();
            //     console.error('Request failed:', errorBody.title);
            // }
            addSettings();
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }
    const addSettings = async () => {
        try {
            console.log(formDatas);
            const response = await Api.fetch({
                url: 'CompetitionsSettings/addCompetitionSettings',
                body: formData,
                method: 'POST',
            });

            if (response) {
                console.log("تمت الاضافة بنجاح");
                props.onHide();
                props.onUpdat();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }


    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">اضافة مسابقة</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        {/* <FormGroup className="col-lg- col-md-6">
                                            <Label htmlFor="competitionId">رقم المسابقة</Label>
                                            <Input type="text" className="form-control" id="competitionId" name='competitionId' placeholder="رقم السابقة" onChange={handleInputChange} />
                                        </FormGroup> */}
                                        <FormGroup className="col-lg- col-md-6">
                                            <Label htmlFor="competitionName">الاسم المسابقة</Label>
                                            <Input type="text" className="form-control" id="competitionName" name='competitionName' placeholder="اسم المسابقة" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg- col-md-6">
                                            <Label htmlFor="startDate">تاريخ بداية المسابقة</Label>
                                            <Input type="date" className="form-control" id="startDate" name='startDate' placeholder="تاريخ بداالمسابقة" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg- col-md-6">
                                            <Label htmlFor="competitionQuestionMarks">العلامة العامة</Label>
                                            <Input type="text" className="form-control" id="competitionQuestionMarks" name='competitionQuestionMarks' placeholder="العلامة العامة" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="tabletsNo">عدد الاجهزة</Label>
                                            <Input type="text" className="form-control" id="tabletsNo" name='tabletsNo' placeholder="عدد الاجهزة" onChange={handleInputChanges} />
                                        </FormGroup>
                                        {/* <FormGroup className="col-lg- col-md-6">
                                            <Label htmlFor="endDate">تاريخ نهاية المسابقة</Label>
                                            <Input type="date" className="form-control" id="endDate" name='endDate' placeholder="تاريخ نهاية المسابقة" onChange={handleInputChange} />
                                        </FormGroup> */}
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={()=>{
                                                
                                                addDirctorate();
                                            }}>اضافة</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
