import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input,  Button } from 'reactstrap';
import Modal from '../../components/Modal'

const ModalEdite = (props) => {
    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم التعديل'}>
                    <div>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h1 className="title font-bold">تعديل مشرفين</h1>
                                    </Col>
                                </Row>
                            </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="name">الاسم الاول</Label>
                                            <Input type="text" className="form-control" id="name" placeholder="اسم المشرف" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="name">الاسم الآخر</Label>
                                            <Input type="text" className="form-control" id="name" placeholder="الكنية" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="name">الاسم الاب</Label>
                                            <Input type="text" className="form-control" id="name" placeholder="اسم الاب" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="name">العمر</Label>
                                            <Input type="text" className="form-control" id="name" placeholder="العمر" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="name">الجنس</Label>
                                            <Input type="text" className="form-control" id="name" placeholder="الجنس" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="email">البريد الاكتروني</Label>
                                            <Input type="email" className="form-control" id="email" placeholder="البريد الاكتروني" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="password">كلمة المرور</Label>
                                            <Input type="password" className="form-control" id="password" placeholder="كلمة المرور" />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="confirmpwd">تاكيد كلمة المرور</Label>
                                            <Input type="password" className="form-control" id="confirmpwd" placeholder="تاكيد كلمة المرور" />
                                        </FormGroup>
                                        <Col md="12">
                                            <Button type="submit" className="btn btn-success waves-effect waves-light m-r-10">تعديل</Button>
                                            <Button type="submit" className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </Col>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalEdite;
