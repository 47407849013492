import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/images/logos/logo00.png';
import PageQuran from '../../components/Pagequran';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Api from '../../tools/api';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../tools/elements/LoadingSpinner';


function StudentPage() {
    const currentDate = new Date();
    const islamicDate = currentDate.toLocaleDateString('ar-SA-u-ca-islamic');
    const gregorianDate = currentDate.toLocaleDateString('en-US');
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const [testmessage, settestmessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const lastClickTime = useRef(0);
    const [dataCpntrolpanel, setdataControlpanel] = useState([]);
    const [dataformquestion, setdataformquestion] = useState([]);
    const [islod, setislod] = useState();
    const [cookies, setCookie, removeCookie] = useCookies(['userData']);
    const [cookiestap, removeCookietap] = useCookies(['userTab']);
    const [dataquestion, setdataquestion] = useState([]);
    useEffect(() => {
        getDataControlpanel();
        getDataformquestion();
    }, []);
    const getDataQuestion = async (data) => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `FormsQuestions/getFormQuestions?compititionId=${data.CompititionID}&trackTypeId=${data.TrackTypeID}&formId=${data.FormID}&questionId=${data.QuestionID}` })
            // console.log(response);
            setdataquestion(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getDataControlpanel = async () => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitionsControlPanels/getExaminerByTablet?userId=${cookiestap.userTab}&tabletId=${cookies.userData.userId}` })
            if (response.examinerByTablet[0] != null) {
                // console.log(response.examinerByTablet[0]);
                setdataControlpanel(response.examinerByTablet[0]);
                // setIsLoading(false);
            } else {
                setdataControlpanel({
                    ExaminerName: "غير محدد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getDataformquestion = async () => {
        try {
            // setIsLoading(true);
            const response = await Api.fetch({ url: `CompetitorsFormsTrackQuestion/getCompetitorIdForExaminer?superciverId=${cookiestap.userTab}` })
            if (response[0] != null) {
                // console.log(response[0]);
                setdataformquestion(response[0]);
                // setIsLoading(false);
                getDataQuestion(response[0]);
                setislod(response[0].QuestionID)
            } else {
                setdataformquestion({
                    ExaminerName: "لا يوجد"
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            getDataQuestion();
            getDataformquestion();
        }, 2000);
        return () => clearInterval(interval);
    });
   
    useEffect(() => {
        if (!dataformquestion.QuestionID) {
            settestmessage('يرجى الانتظار إلى حين تحديد الطالب')
            setIsLoading(true);
        }
        if (dataformquestion) {
            console.log(dataformquestion.QuestionID);
            setIsLoading(false)
        }

    }, [islod]);
    return (
        <>
            {isLoading ? <LoadingSpinner text={testmessage} /> : ''}
            <div className='exam-page-student'>
                <div className='exam-head'>
                    <div style={{ textAlign: 'right' }}>
                        <h4>{cookies.userData.userName} <span>:الجهاز</span></h4>
                    </div>
                    <div className='text-center'>
                        <h2>المسابقة القرانية الكبرى هـ١٤٤٥</h2>
                        <h2>بطاقة تقييم المتسابقين</h2>
                    </div>
                    <div>
                        <Link to={'/'}>
                            <img src={logo} alt="wrapkit" width={96} />
                        </Link>
                    </div>
                </div>
                <div className='exam-data'>
                    <h4>رقم السؤال: 5/{dataformquestion.QuestionID}</h4>
                    <h4>{dataformquestion.CompetitorID}<span>: ID</span></h4>
                    <h4>{dataformquestion.GenderID === 1 ? 'الطالب' : 'الطالبة'}: {dataformquestion.FullName}</h4>
                    <h4><span>رقم النموذج:</span> {dataformquestion.FormID}</h4>
                    <h4>المسار: {dataformquestion.TrackTypeName}</h4>
                </div>
                <div className='student-body'>
                    <div style={{ width: "700px" }}>
                        <h2 className='text-center test-header1'>
                            :أكمل من قوله تعالى
                        </h2>
                        {dataformquestion.TrackTypeID == 2 ?
                            <h3 className='text-center test-header2'>
                            برواية حفص عن عاصم
                        </h3>
                        :''}
                        <h3 className='text-center test-header3' dir='rtl'>
                            {dataquestion.verseBeginning} ....
                        </h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentPage;