import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ModalCreate from './ModalCreate'
import { MdDeleteForever } from "react-icons/md";
import { FaPencil } from "react-icons/fa6";
import ModalEdite from './ModalEdite';
import ModalDelete from './ModalDelete';
import Api from '../../../tools/api';
import LoadingSpinner from '../../../tools/elements/LoadingSpinner';

const EvaluationCriteria = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalEditeShow, setModalEditeShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [itemid, setItem] = useState([])
    const [evaluationCriterions, setEvaluationCriterions] = useState([])

    useEffect(() => {
        getEvaluationCriterions();
    }, []);
    const getEvaluationCriterions = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: 'EvaluationCriterions/getAllEvaluationCriterions' })
            if (response) {
                console.log(response);
                setEvaluationCriterions(response);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <>
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShow(true)}>
                    إدخال معايير تقييم القارئ
                </Button>
                <ModalCreate
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onUpdat={() => getEvaluationCriterions()}

                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>الرقم</th>
                            <th>اسم المديرية</th>
                            <th>اسم المعيار</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <LoadingSpinner/>
                        ) : (
                            evaluationCriterions == null ? (
                                <div>لا يوجد معايير</div>
                            ) : (
                                <>
                                    {evaluationCriterions.map((item, index) => (
                                        <tr className='table-tr'>
                                            <td className='head-color1'>{item.evaluationCriterionId}</td>
                                            <td className='head-color2'>{item.competitionName}</td>
                                            <td className='head-color2'>{item.evaluationCriterionName}</td>
                                            <td className='head-color1'>
                                                <MdDeleteForever onClick={() => {
                                                    setItem(item)
                                                    setModalDeleteShow(true);
                                                }} /> / <FaPencil onClick={() => {
                                                    // setItem(item);
                                                    // setModalEditeShow(true)
                                                }} /></td>
                                            <ModalDelete
                                                show={modalDeleteShow}
                                                onHide={() => setModalDeleteShow(false)}
                                                onUpdat={() => getEvaluationCriterions()}
                                                itemid={itemid}
                                            />
                                        </tr>
                                    ))}
                                </>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default EvaluationCriteria;