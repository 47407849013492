import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

const ModalCreate = (props) => {
    const [dirctorate, setDirctorate] = useState([])
    const [selectDirctorate, setselectDirctorate] = useState(0)

    const [formData, setFormData] = useState({
        centerName: null,
        directorateId: null
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        getDirctorate();
    }, []);

    const getDirctorate = async () => {
        try {
            const response = await Api.fetch({ url: 'Directorates' });
            if (response) {
                setDirctorate(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addDirctorate = async () => {
        try {
            const response = await Api.fetch({
                url: 'Centers/addCenter',
                body: formData,
                method: 'POST',
            });
            console.log(response);
            if (response.ok) {
                toast.success("تمت الاضافة بنجاح");
                props.onHide();
                props.onUpdat();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">اضافة مركز تحفيظ</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="directorateId">المديرية</Label>
                                            <select id="directorateId" name="directorateId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                                setselectDirctorate(e.target.value);
                                            }}>
                                                <option>اختر المديرية</option>
                                                {dirctorate.map((item, index) => (
                                                    <option key={index} value={item.directorateId}>{item.directorateIname}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="centerName">الاسم المركز</Label>
                                            <Input type="text" className="form-control" id="centerName" name='centerName' placeholder="اسم المديرية" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={addDirctorate}>اضافة</Button>
                                            <Button  className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
