import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Lottie from 'react-lottie';
import animationData from '../../lottie/login.json';
import logo from '../../assets/images/logos/logo00.png';
import { useCookies } from 'react-cookie';
import Api from "../../tools/api";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import LoadingSpinner from "../../tools/elements/LoadingSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const Login = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['userData']);

    useEffect(() => {
        removeCookie('userData');
    }, []);
    const [isLoading, setIsLoading] = useState(false);
    const [supervicer, setsupervicer] = useState('');
    const [state, setState] = useState({})
    // const [cookie, setCookie] = useCookies('token')
    const [showPassword, setShowPassword] = useState(false);
    const [statepass, setStatepass] = useState({ password: "" });
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const callLogin = async () => {
        try {
            console.log(isLoading);
            setIsLoading(true);
            if (!state.userId || !state.password || !supervicer) {
                console.log('يرجى ملء جميع الحقول.');
                toast.error('يرجى ملء جميع الحقول.');
                setIsLoading(false);
                return;
            }
            console.log(state);
            const response = await axios.post(`https://quraniccompetition.com/api/Auth/authenticate?supervisorId=${supervicer}`, state);
            // Check API Response  
            console.log(response);

            if (response.data.response != null) {
                // Save token (appState - Cookies)
                if (response.status === 200) {
                    toast.success('تم تسجيل الدخول بنجاح');
                    setCookie('userData', response.data.response);
                    setCookie('userTab', supervicer);
                    // Redirect to homepage     
                    setIsLoading(false);
                    window.location.href = '/';
                }
            } else {
                toast.error('خطا في البيانات يرجى اعادة المحاولة');
            }
            setIsLoading(false);

        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Handle 400 Bad Request error
                setIsLoading(false);
                console.error('Bad Request Error:', error.response.data);
                toast.error(error.response.data.message ?? error.response.data);
            } else {
                setIsLoading(false);
                // Handle other errors
                console.error('An error occurred:', error.message);
                // Display a generic error message to the user
                toast.error('An error occurred. Please try again later.');
            }
        }
    }

    return (
        <div id="main-wrapper" dir="rtl">
            <div className="page-wrapper">
                <div className="container-fluid">
                    {isLoading ? <LoadingSpinner /> : ''}
                    <Toaster />
                    <div className="row" style={{ height: "100vh", overflow: "auto" }}>
                        <div className="col-lg-6 col-ms-12">
                            <Lottie
                                options={defaultOptions}
                            />
                        </div>
                        <div className="col-lg-6 col-ms-12">
                            <div className="login_form">
                                <img src={logo} alt="wrapkit" />
                                <h3>تسجيل الدخول</h3>
                                <div>
                                    <label htmlFor="supervicer">معرف المشرف</label><br />
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        id="supervicer"
                                        placeholder="معرف المشرف"
                                        value={supervicer}
                                        onChange={(e) => setsupervicer(e.target.value)}
                                        inputMode="numeric"
                                    />
                                    <label>معرف الجهاز</label><br />
                                    <input type="text" placeholder="معرف الجهاز"
                                        onChange={(e) => {
                                            setState({ ...state, userId: e.target.value })
                                        }}
                                        inputMode="numeric"
                                    />
                                </div>
                                <div className="div-pass">
                                    <label>كلمة المرور</label><br />

                                    <div className="parent-pass">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="كلمة المرور"
                                            value={state.password}
                                            onChange={(e) => setState({ ...state, password: e.target.value })}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    callLogin();
                                                }
                                            }}
                                            inputMode="numeric"

                                        />
                                        <FontAwesomeIcon
                                            icon={showPassword ? faEyeSlash : faEye}
                                            onClick={togglePasswordVisibility}
                                            className="password-toggle-icon"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button onClick={callLogin}>دخول</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


    // return (
    //     <div id="main-wrapper" dir="rtl">
    //         <div className="page-wrapper">
    //             <div className="container-fluid">
    //             {isLoading? <LoadingSpinner />:''}
    //             <Toaster />
    //                 <div className="row" style={{ height: "100vh", overflow: "auto" }}>
    //                     <div className="col-lg-6 col-ms-12">
    //                         <Lottie
    //                             options={defaultOptions}
    //                         />
    //                     </div>
    //                     <div className="col-lg-6 col-ms-12">
    //                         <div className="login_form">
    //                             <img src={logo} alt="wrapkit" />
    //                             <h3>تسجيل الدخول</h3>
    //                             <div>
    //                                 <label>معرف المشرف</label><br />
    //                                 <input type="text" placeholder="معرف المشرف"
    //                                     onChange={(e) => {
    //                                         setsupervicer(e.target.value)
    //                                     }} />
    //                                 <label>معرف الجهاز</label><br />
    //                                 <input type="text" placeholder="معرف الجهاز"
    //                                     onChange={(e) => {
    //                                         setState({ ...state, userId: e.target.value })
    //                                     }} />
    //                             </div>
    //                             <div>
    //                                 <label>كلمة المرور</label><br />
    //                                 <input type="password" placeholder="كلمة المرور"
    //                                     onChange={(e) => {
    //                                         setState({ ...state, password: e.target.value })
    //                                     }}
    //                                     onKeyPress={(e) => {
    //                                         if (e.key === 'Enter') {
    //                                             callLogin();
    //                                         }
    //                                     }}
    //                                 />
    //                             </div>
    //                             <div>
    //                                 <button onClick={callLogin}>دخول</button>
    //                             </div>
    //                         </div>

    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
}

Login.propTypes = {
    classes: PropTypes.object
};

export default Login;