import React, { useState } from 'react';
import Header from '../components/header/header';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import {
    Route,
    Routes,
} from "react-router-dom";
import ManagementUser from './ManagementUser/ManagementUser';
import ExaminerPage from './Examiner/ExaminerPage';
import Dirctorate from './admin/ManagementDirctorate/Dirctorate';
import Centers from './admin/ManagementCenters/Centers';
import Examiners from './admin/ManagementExaminers/Examiners';
import Competitions from './admin/ManagementCompetitions/Competitions';
import Tracks from './admin/ManagementTracks/Tracks';
import CompetitionsForms from './admin/ManagementCompetitionsForms/CompetitionsForms';
import Settings from './admin/ManagementSettings/Settings';
import CompetitorsInfo from './admin/ManagementCompetitorsInfo/CompetitorsInfo';
import EvaluationCriteria from './admin/ManagementEvaluationCriteria/EvaluationCriteria';
import EvaluationTypes from './admin/ManagementEvaluationTypes/EvaluationTypes';
import EvaluationTypesSubtractionMarks from './admin/ManagementEvaluationTypeSubtractionMarks/EvaluationTypesSubtractionMarks';
import FormsQuestion from './admin/ManagementFormsQuestion/FormsQuestion';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import {FaRegStarHalfStroke} from 'react-icons/fa6';
import UplodeStudent from './admin/ManagementFile/UplodeStudent';


const Dashboards = () => {
    const [cookies] = useCookies(['userData']);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(true);
    const [isSubMenuOpen1, setIsSubMenuOpen1] = useState(true);
    const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(true);
    const [isSubMenuOpen4, setIsSubMenuOpen4] = useState(true);
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleNavClick = (path) => {
        console.log('====================================');
        console.log(path);
        console.log('====================================');
        setActiveLink(path);
    };
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };
    const toggleSubMenu1 = () => {
        setIsSubMenuOpen1(!isSubMenuOpen1);
    };
    const toggleSubMenu2 = () => {
        setIsSubMenuOpen2(!isSubMenuOpen2);
    };
    const toggleSubMenu4 = () => {
        setIsSubMenuOpen4(!isSubMenuOpen4);
    };
    var hist = createBrowserHistory();
    return (
        <>
            <div className='header-dash'>
                <Header />
            </div>
            <div className='body-dash'>
                <div className='dash-left' >
                    <Routes>
                        <Route path="/user" element={<ManagementUser />} />
                        <Route path="/dirctorate" element={<Dirctorate />} />
                        <Route path="/centers" element={<Centers />} />
                        <Route path="/examiners" element={<Examiners />} />
                        <Route path="/competitions" element={<Competitions />} />
                        <Route path="/tracks" element={<Tracks />} />
                        <Route path="/competitionsforms" element={<CompetitionsForms />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/competitorsinfo" element={<CompetitorsInfo />} />
                        <Route path="/evaluationcriteria" element={<EvaluationCriteria />} />
                        <Route path="/evaluationtypes" element={<EvaluationTypes />} />
                        <Route path="/evaluationtypesmarks" element={<EvaluationTypesSubtractionMarks />} />
                        <Route path="/formsquestion" element={<FormsQuestion />} />
                        <Route path="/uplodestudent" element={<UplodeStudent />} />
                    </Routes>
                </div>
                <div className='dash-right'>
                    {cookies.userData.roleId == 1 ? (
                        <>
                            <div className='dash-item'>
                                <Link state={{ name: 'najjar' }} className="nav-link" to={'/dashboards/user'} onClick={() => handleNavClick('/dashboard/batch')}>
                                    المشرفين
                                </Link>
                            </div>
                            <div className='dash-item'>
                                <Link className="nav-link" to={'/dashboards'} onClick={() => handleNavClick('/dashboard/batch')}>
                                    التقارير
                                </Link>
                            </div>
                        </>
                    ) : (
                        cookies.userData.roleId == 2 ?
                            <>
                                <div className='dash-item' onClick={toggleSubMenu2}>
                                    <a className="nav-link">
                                        الادارة
                                    </a>
                                </div>
                                <div className={isSubMenuOpen2 ? 'dash-item-submenu open' : 'dash-item-submenu'}>
                                    <div onClick={() => handleNavClick('/dashboards/user')} className={activeLink === '/dashboards/user' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className='nav-link' to={'/dashboards/user'} >
                                            الممتحنين
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/dirctorate')} className={activeLink === '/dashboards/dirctorate' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className='nav-link' to={'/dashboards/dirctorate'} >
                                            المديريات
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/centers')} className={activeLink === '/dashboards/centers' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/centers'} >
                                            مراكز التحفيظ
                                        </Link>
                                    </div>

                                    <div onClick={() => handleNavClick('/dashboards/examiners')} className={activeLink === '/dashboards/examiners' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/examiners'}>
                                            لجنة المقرئين
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/settings')} className={activeLink === '/dashboards/settings' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/settings'}>
                                            اعدادات
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/competitorsinfo')} className={activeLink === '/dashboards/competitorsinfo' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/competitorsinfo'}>
                                            معلومات المتسابقين
                                        </Link>
                                    </div>
                                </div>
                                <div className='dash-item' onClick={toggleSubMenu4}>
                                    <a className="nav-link">
                                        رفع الملفات
                                    </a>
                                </div>
                                <div className={isSubMenuOpen4 ? 'dash-item-submenu open' : 'dash-item-submenu'}>
                                    <div onClick={() => handleNavClick('/dashboards/uplodestudent')} className={activeLink === '/dashboards/uplodestudent' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className='nav-link' to={'/dashboards/uplodestudent'} >
                                            الطلاب
                                        </Link>
                                    </div>
                                </div>
                                <div className='dash-item' onClick={toggleSubMenu}>
                                    <a className="nav-link">
                                        المسابقة
                                    </a>
                                </div>
                                <div className={isSubMenuOpen ? 'dash-item-submenu open' : 'dash-item-submenu'}>
                                    <div onClick={() => handleNavClick('/dashboards/competitions')} className={activeLink === '/dashboards/competitions' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/competitions'}>
                                            معلومات المسابقة
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/tracks')} className={activeLink === '/dashboards/tracks' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/tracks'}>
                                            أنواع المسارات
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/competitionsforms')} className={activeLink === '/dashboards/competitionsforms' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/competitionsforms'}>
                                            النماذج
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/formsquestion')} className={activeLink === '/dashboards/formsquestion' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/formsquestion'}>
                                            اسئلة النماذج
                                        </Link>
                                    </div>
                                </div>
                                <div className='dash-item' onClick={toggleSubMenu1}>
                                    <a className="nav-link">
                                        التقييم
                                    </a>
                                </div>
                                <div className={isSubMenuOpen1 ? 'dash-item-submenu open' : 'dash-item-submenu'}>
                                    <div onClick={() => handleNavClick('/dashboards/evaluationcriteria')} className={activeLink === '/dashboards/evaluationcriteria' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/evaluationcriteria'}>
                                            معايير التقييم
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/evaluationtypes')} className={activeLink === '/dashboards/evaluationtypes' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/evaluationtypes'}>
                                            أنواع أخطاء معايير التقييم
                                        </Link>
                                    </div>
                                    <div onClick={() => handleNavClick('/dashboards/evaluationtypesmarks')} className={activeLink === '/dashboards/evaluationtypesmarks' ? 'dash-item-menu active-side' : 'dash-item-menu'}>
                                        <div style={{ padding: '0rem 0rem 0rem 0.5rem' }}>
                                            <FaRegStarHalfStroke />
                                        </div>
                                        <Link className="nav-link" to={'/dashboards/evaluationtypesmarks'}>
                                            علامات أخطاء معايير التقييم
                                        </Link>
                                    </div>
                                </div>
                            </>
                            :
                            <>

                            </>
                    )}


                </div>
            </div>
        </>
    );
}

export default Dashboards;
