import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ModalCreate from './ModalCreate'
import { MdDeleteForever } from "react-icons/md";
import { FaArrowLeft, FaArrowRight, FaPencil, FaPersonCirclePlus } from "react-icons/fa6";
import ModalEdite from './ModalEdite';
import ModalDelete from './ModalDelete';
import Api from '../../../tools/api';
import LoadingSpinner from '../../../tools/elements/LoadingSpinner';
import ReactPaginate from 'react-paginate';
import { Toaster } from 'react-hot-toast';

const Examiners = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalEditeShow, setModalEditeShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [itemid, setItem] = useState([])
    const [examiners, setExaminers] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const itemPerPage = 10;
    useEffect(() => {
        getDirctorate();
    }, []);
    const getDirctorate = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: 'Committees' })
            if (response) {
                console.log(response);
                setExaminers(response);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const pageCount = Math.ceil(examiners.length / itemPerPage);
    const pagesVisited = currentPage * itemPerPage;

    const displayexaminers = examiners
        .slice(pagesVisited, pagesVisited + itemPerPage)
        .map((item, index) => (
            <tr className='table-tr'>
                <td className='head-color1'>{item.examinerId}</td>
                <td className='head-color2'>{item.examinerName}</td>
                <td className='head-color2'>{item.genderId == 1 ? "ذكر" : "انثى"}</td>
                <td className='head-color2'>{item.whatsApp}</td>
                <td className='head-color2'>{item.telgram}</td>
                <td className='head-color1'><MdDeleteForever onClick={() => {
                    setItem(item)
                    setModalDeleteShow(true);
                }} /> / <FaPencil onClick={() => {
                    setItem(item);
                    setModalEditeShow(true)
                }} /></td>
                <ModalEdite
                    show={modalEditeShow}
                    onHide={() => setModalEditeShow(false)}
                    onUpdat={() => getDirctorate()}
                    itemid={itemid}
                />
                <ModalDelete
                    show={modalDeleteShow}
                    onHide={() => setModalDeleteShow(false)}
                    onUpdat={() => getDirctorate()}
                    itemid={itemid}
                />
            </tr>
        ));
    return (
        <>
            <Toaster />
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShow(true)}>
                    <div style={{ padding: '0rem 0rem 0rem 1rem' }}>
                        <FaPersonCirclePlus />
                    </div>
                    اضافة مقرئ
                </Button>
                <ModalCreate
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onUpdat={() => getDirctorate()}
                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>رقم الشيخ</th>
                            <th>اسم الشيخ</th>
                            <th>الجنس</th>
                            <th>رقم الوتس</th>
                            <th>معرف التلغرام</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            examiners == null ? (
                                <div>لا يوجد مشرفين</div>
                            ) : (
                                displayexaminers
                            )
                        )}
                    </tbody>
                </table>
                <div className='head-state'>
                    <ReactPaginate
                        previousLabel={<FaArrowRight />}
                        nextLabel={<FaArrowLeft />}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </>
    );
}

export default Examiners;