import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ModalCreate from './ModalCreate'
import { MdDeleteForever } from "react-icons/md";
import { FaPencil } from "react-icons/fa6";
import ModalEdite from './ModalEdite';
import ModalDelete from './ModalDelete';
import Api from '../../../tools/api';
import LoadingSpinner from '../../../tools/elements/LoadingSpinner';

const FormsQuestion = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalEditeShow, setModalEditeShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [itemid, setItem] = useState([])
    const [formsquestion, setFormsQuestion] = useState([])
    const [formData, setFormData] = useState({
        competitionId: 0,
        trackTypeId: 0,
        formId: 0,
        questionId: 0,
        questionMarks: 0,
        surahNo: 0,
        verseNo: 0,
        verseText: "string",
        questionClassificationId: 0,
        verseBeginning: "string",
        verseEnd: "string"
    });
    useEffect(() => {
        getFormsQuestion();
    }, []);
    const getFormsQuestion = async () => {
        try {
            setIsLoading(true);
            const response = await Api.fetch({ url: 'FormsQuestions' })
            if (response) {
                console.log(response);
                setFormsQuestion(response);
                setIsLoading(false);
            }
        } catch (error) { 
            console.error('Error fetching data:', error);
        }
    };
    return (
        <>
            <div className='body-dash-mangment' dir='rtl'>
                <Button className='button-add' variant="primary" onClick={() => setModalShow(true)}>
                    إدخال اسئلة النماذج
                </Button>
                <ModalCreate
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onUpdat={() => getFormsQuestion()}
                />
                <table className='dash-table'>
                    <thead>
                        <tr className='head'>
                            <th style={{ borderTopRightRadius: 16 + 'px' }}>رقم السؤال</th>
                            <th>المسابقة</th>
                            <th>النموذج</th>
                            <th>المسار</th>
                            <th>التصنيف</th>
                            <th>العلامة</th>
                            <th>رقم السورة</th>
                            <th>بداية الآية</th>
                            <th>نهاية الآية</th>
                            <th>رقم الآية</th>
                            <th>نص الآية</th>
                            <th style={{ borderTopLeftRadius: 16 + 'px' }}>الأحداث</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <LoadingSpinner/>
                        ) : (
                            formsquestion.length == 0 ? (
                                <div>لا يوجد معايير</div>
                            ) : (
                                <>
                                    {formsquestion.map((item, index) => (
                                        <tr className='table-tr'>
                                            <td className='head-color1'>{item.questionId}</td>
                                            <td className='head-color2'>{item.competitionName}</td>
                                            <td className='head-color2'>{item.formText}</td>
                                            <td className='head-color2'>{item.trackTypeName}</td>
                                            <td className='head-color2'>{item.questionClassificationId}</td>
                                            <td className='head-color2'>{item.questionMarks}</td>
                                            <td className='head-color2'>{item.surahNo}</td>
                                            <td className='head-color2'>{item.verseBeginning}</td>
                                            <td className='head-color2'>{item.verseEnd}</td>
                                            <td className='head-color2'>{item.verseNo}</td>
                                            <td className='head-color2'>{item.verseText}</td>
                                            <td className='head-color1'>
                                                <MdDeleteForever onClick={() => {
                                                    setItem(item)
                                                    setModalDeleteShow(true);
                                                }} /> / <FaPencil onClick={() => {
                                                    // setItem(item);
                                                    // setModalEditeShow(true)
                                                }} /></td>
                                            <ModalDelete
                                                show={modalDeleteShow}
                                                onHide={() => setModalDeleteShow(false)}
                                                onUpdat={() => getFormsQuestion()}
                                                itemid={itemid}
                                            />
                                        </tr>
                                    ))}
                                </>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default FormsQuestion;