import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import {Route,Routes,HashRouter} from "react-router-dom";
import Components from "./views/components/components.jsx";
import Login from "./views/Auth/login.jsx";
import Error404 from "./views/Error404.jsx";
import Dashboards from "./dashboards/Dashboards.jsx";
import ManagementUser from "./dashboards/ManagementUser/ManagementUser.jsx";
import ExaminerPage from "./dashboards/Examiner/ExaminerPage.jsx";
import PageQuran from "./components/Pagequran.jsx";
import Dirctorate from "./dashboards/admin/ManagementDirctorate/Dirctorate.jsx";
import Centers from "./dashboards/admin/ManagementCenters/Centers.jsx";
import Examiners from "./dashboards/admin/ManagementExaminers/Examiners.jsx";
import Competitions from "./dashboards/admin/ManagementCompetitions/Competitions.jsx";
import Tracks from "./dashboards/admin/ManagementTracks/Tracks.jsx";
import CompetitionsForms from "./dashboards/admin/ManagementCompetitionsForms/CompetitionsForms.jsx";
import Settings from "./dashboards/admin/ManagementSettings/Settings.jsx";
import CompetitorsInfo from "./dashboards/admin/ManagementCompetitorsInfo/CompetitorsInfo.jsx";
import EvaluationCriteria from "./dashboards/admin/ManagementEvaluationCriteria/EvaluationCriteria.jsx";
import EvaluationTypes from "./dashboards/admin/ManagementEvaluationTypes/EvaluationTypes.jsx";
import EvaluationTypesSubtractionMarks from "./dashboards/admin/ManagementEvaluationTypeSubtractionMarks/EvaluationTypesSubtractionMarks.jsx";
import FormsQuestion from "./dashboards/admin/ManagementFormsQuestion/FormsQuestion.jsx";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import AdminPage from "./dashboards/admin/AdminPage.jsx";
import Test from "./views/test.jsx";
import StudentPage from "./dashboards/student/StudentPage.jsx";
import UplodeStudent from "./dashboards/admin/ManagementFile/UplodeStudent.jsx";

function App() {
  const [cookies] = useCookies(['userData']);

  return (
    <HashRouter>
      <Routes>
        <Route path="/*" element={<Error404 />} />
        <Route path="/" element={<Components />} />
        <Route path="/examinerpage" element={<ExaminerPage />} />
        <Route path="/studentpage" element={<StudentPage />} />
        <Route path="/qq" element={<PageQuran />} />
        <Route path="/login" element={<Login />} />
        <Route path="/adminpage" element={<AdminPage />} />
        <Route path="/test" element={<Test />} />
        {cookies.userData ?
          <Route path="/dashboards" element={<Dashboards />}>
            {cookies.userData.roleId == 1 ?
              <>
                <Route path="user" element={<ManagementUser />} />
              </>
              :
              cookies.userData.roleId == 2 ?
              <>
                <Route path="user" element={<ManagementUser />} />
                <Route path="dirctorate" element={<Dirctorate />} />
                <Route path="centers" element={<Centers />} />
                <Route path="examiners" element={<Examiners />} />
                <Route path="competitions" element={<Competitions />} />
                <Route path="tracks" element={<Tracks />} />
                <Route path="competitionsforms" element={<CompetitionsForms />} />
                <Route path="settings" element={<Settings />} />
                <Route path="competitorsinfo" element={<CompetitorsInfo />} />
                <Route path="evaluationcriteria" element={<EvaluationCriteria />} />
                <Route path="evaluationtypes" element={<EvaluationTypes />} />
                <Route path="evaluationtypesmarks" element={<EvaluationTypesSubtractionMarks />} />
                <Route path="formsquestion" element={<FormsQuestion />} />
                <Route path="uplodestudent" element={<UplodeStudent />} />
              </>
              :<>

              </>
            }
          </Route>
          :
          // <Route path="/dashboards" element={<Error404 />} />
          ""
        }
      </Routes>
    </HashRouter>
  );
}

export default App;
