import React, { useEffect } from 'react';
import './Modal.css';
import { Button } from 'reactstrap';
// import closeSquare from '../images/close-square.svg';

const Modal = ({ children, onClose, title, height, width, scale }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="modal-overlay" onClick={onClose} >
      <div className="modal-content" style={{ height, width, scale }} onClick={(e) => e.stopPropagation()}>
        <div className="modal-header" onClick={onClose}>
          <h4>{title}</h4>
          <span>X</span>
        </div>
        <div className="modal-content-wrapper">
          {children}
        </div>
        {/* <div>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={onClose}>save</Button>
        </div> */}
      </div>
    </div>
  );
};

export default Modal;
