/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, NavbarBrand, Navbar, Nav, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';

import logo from '../../assets/images/logos/logo2.svg';
import { useCookies } from 'react-cookie';
import Api from '../../tools/api';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [dataCpntrolpanel, setdataControlpanel] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['userData']);
    const [cookiestap, removeCookietap] = useCookies(['userTab']);


    useEffect(() => {
        
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            getDataControlpanel();
            console.log(cookies);
            if (!dataCpntrolpanel) {
                console.log('kj');
                // onLogout();
            }
        }, 2000);

        return () => clearInterval(interval);
    });
    const getDataControlpanel = async () => {
        if (cookies) {
            try {
                const response = await Api.fetch({ url: `CompetitionsControlPanels/getExaminerByTablet?userId=${cookiestap.userTab}&tabletId=${cookies.userData.userId}` })
                setdataControlpanel(response.examinerByTablet[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    const onLogout = async () => {
        // Accessing the 'userId' from userData cookie
        const userData = cookies['userData'];
        const userTab = cookies['userTab'];
        console.log(userTab);
        console.log(userData);
        if (userData && userData.userId) {
            // call api to remove the token
            await Api.fetch({ method: 'POST', url: `Auth/logout?id=${userData.userId}&supervicer=${userTab}` });
        }
        // reset cookie
        window.location.href = '/'
        removeCookie('userData');
        removeCookietap('userTab');
    }

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative" dir='ltr'>
                    <Navbar className="navbar-expand-lg h1-nav" >
                        <NavbarBrand href="#"><img src={logo} alt="wrapkit" height={60} /></NavbarBrand>
                        <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                        <Collapse isOpen={isOpen} navbar id="header1">
                            <Nav navbar className="ms-auto mt-2 mt-lg-0">
                                <Nav navbar className="ms-auto">
                                    <NavItem>
                                        <Link className="nav-link" to={"/"}>
                                            الصفحة الرئيسية
                                        </Link>
                                    </NavItem>
                                </Nav>
                                {cookies.userData ? (
                                    cookies.userData.roleId == 1 || cookies.userData.roleId == 2 ?
                                        <NavItem>
                                            <Link className="nav-link" to={'/dashboards'}>
                                                لوحة التحكم
                                            </Link>
                                        </NavItem>
                                        :
                                        <>

                                        </>
                                ) : (
                                    ''
                                )}
                                <NavItem>
                                    {cookies.userData ? (
                                        <Link className="btn btn-outline-success" onClick={onLogout}>
                                            تسجيل الخروج
                                        </Link>
                                    ) : (
                                        <Link className="btn btn-outline-success" to={"/login"}>
                                            تسجيل الدخول
                                        </Link>
                                    )}
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );
}
export default Header;
