import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../components/Modal'
import Api from '../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

const ModalCreate = (props) => {
    const [cookies] = useCookies(['userData']);
    const [genders, setGenders] = useState([])
    const [role, setrole] = useState(2)
    const [formData, setFormData] = useState([])
    const [selectgenders, setselectGenders] = useState(0);
    useEffect(() => {
        getGenders();
        let roleid; // Declaring roleid with let instead of const
        if (cookies.userData.roleId == 1) {
            setrole(2);
        }
        console.log(role);
        setFormData({
            userId: 0,
            userName: null,
            genderId: null,
            roleId: role,
            email: "",
            password: null,
            token: "string"
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const getGenders = async () => {
        try {
            const response = await Api.fetch({ url: 'Genders' });
            if (response) {
                setGenders(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addUser = async () => {
        console.log(formData);
        try {
            const response = await Api.fetch({
                url: 'Users',
                body: formData,
                method: 'POST',
            });

            if (response.ok) {
                toast.success("تمت الاضافة بنجاح");
                props.onHide();
                props.onUpdat();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    {cookies.userData.roleId == 1 ?
                                        <h1 className="title font-bold">اضافة مشرفين</h1>
                                        :
                                        <h1 className="title font-bold">اضافة حساب</h1>
                                    }
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="userName">الاسم الاول</Label>
                                            <Input type="text" className="form-control" id="userName" name='userName' placeholder="اسم المشرف" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="genderId">الجنس</Label>
                                            <select id="genderId" name="genderId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                                setselectGenders(e.target.value);
                                            }}>
                                                <option>اختر الجنس</option>
                                                {genders.map((item, index) => (
                                                    <option key={index} value={item.genderId}>{item.genderName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-6 col-md-6">
                                            <Label htmlFor="password">كلمة المرور</Label>
                                            <Input type="password" className="form-control" id="password" name='password' placeholder="كلمة المرور" onChange={handleInputChange} />
                                        </FormGroup>
                                        {cookies.userData.roleId == 2 ?
                                            <FormGroup className="col-md-6 ml-6">
                                                <Label htmlFor="roleId">النوع</Label>
                                                <select id="roleId" name="roleId" className="form-control" onChange={(e) => {
                                                    setrole(e.target.value);
                                                    handleInputChange(e);
                                                }}>
                                                    <option>اختر النوع</option>
                                                    <option value={3}>ممتحن</option>
                                                    <option value={4}>طالب</option>
                                                </select>
                                            </FormGroup>
                                            :
                                            ''
                                        }

                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={addUser}>اضافة</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
