import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Modal from '../../../components/Modal'
import Api from '../../../tools/api';
import { useState } from 'react';
import { useEffect } from 'react';

const ModalCreate = (props) => {
    const [genders, setGenders] = useState([])
    const [selectgenders, setselectGenders] = useState(0)
    const [competitions, setCompetitions] = useState([])

    const [formData, setFormData] = useState({
        compititionId: 0,
        trackTypeId: 0,
        formId: 0,
        formText: "string"
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        getCompetitions();
        getGenders();
    }, []);
    const getCompetitions = async () => {
        try {
            const response = await Api.fetch({ url: 'Competitions' })
            if (response) {
                console.log(response);
                setCompetitions(response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getGenders = async () => {
        try {
            const response = await Api.fetch({ url: 'TracksTypes' });
            if (response) {
                setGenders(response)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addExaminers = async () => {
        console.log(formData);
        try {
            const response = await Api.fetch({
                url: 'CompetitionsForms/addCompetitionsForms',
                body: formData,
                method: 'POST',
            });

            if (response) {
                console.log("تمت الاضافة بنجاح");
                props.onHide();
                props.onUpdat();
            } else {
                const errorBody = await response.json();
                console.error('Request failed:', errorBody.title);
            }
        } catch (error) {
            console.error('Network error:', error.message);
        }
    }

    return (
        <>
            {props.show && (
                <Modal onClose={props.onHide} title={'فورم الاضافة'}>
                    <div>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">اضافة مديرية</h1>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Form className="row">
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="formId">رقم النموذج</Label>
                                            <Input type="text" className="form-control" id="formId" name='formId' placeholder="رقم النموذج" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="formText">الاسم النموذج</Label>
                                            <Input type="text" className="form-control" id="formText" name='formText' placeholder="اسم النموذج" onChange={handleInputChange} />
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="compititionId">المسابقة</Label>
                                            <select id="compititionId" name="compititionId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                            }}>
                                                <option>اختر المسابقة</option>
                                                {competitions.map((item, index) => (
                                                    <option key={index} value={item.competitionId}>{item.competitionName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 col-md-6">
                                            <Label htmlFor="trackTypeId">المسار</Label>
                                            <select id="trackTypeId" name="trackTypeId" className="form-control" onChange={(e) => {
                                                handleInputChange(e);
                                                setselectGenders(e.target.value);
                                            }}>
                                                <option>اختر المسار</option>
                                                {genders.map((item, index) => (
                                                    <option key={index} value={item.trackTypeId}>{item.trackTypeName}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-md-12">
                                            <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={addExaminers}>اضافة</Button>
                                            <Button className="btn btn-inverse waves-effect waves-light" onClick={props.onHide}>الغاء</Button>
                                        </FormGroup>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ModalCreate;
